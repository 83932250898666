import React from 'react'
import { connect } from 'react-redux';
import { frontEndLogs } from '../../action/logs/frontEndLogs';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { Link, Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


class ApplicationLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            customPath: '',
            redirect: false
          }
    }
    componentDidMount(){
        this.props.appvalidations([]);
        this.props.getAmountRequested('');
        this.props.removeSavedata([]);
    }

    backgroundApply=()=>{
        return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="applyLandingLogin-img-sec">
                <img src="images/humm-leftPanel-bg.jpg" alt="Background" />

            </div>
        </div>;
    }

    applyNowFlow= async()=>{
        localStorage.setItem('fromWhere', 'apply_now');
        const trackingId = uuidv4();
        localStorage.setItem('user_tracking_id', trackingId);
        frontEndLogs('apply_now_from_application_clicked', false, 200, 'application_landing_page')
        .catch(error => console.error(error));
        
        this.setState({customPath: '/application'});
        this.setState({redirect: true});
        analyticsClickEvent('apply_now_from_application', '');
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.customPath} />;
          }
        return ( 
            <div className="layout-fixed">
            <div className="wrapper login-screen scroll-sec-fix">
              {this.backgroundApply()}
              <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                  <a className='help-support' href="https://www.shophumm.com/uk/help-centre/" 
                target="_blank" rel="noopener noreferrer">
                    <img src="images/help-support.svg" className="img-fluid" alt="logo" />
                  </a>
                <div className="section-form--app-landing">
                  <div className="form-header">
                    <span className="section-logo">
                      <img src="images/logo.svg" className="img-fluid" alt="logo" />
                    </span>
                    <span className="section-heading">
                      <h4>Let's get started!</h4>
                    </span>

                  </div>

                  <div className="form-group new-apply-now-button">
                    <button type="button" id="applyButton" 
                    className="btn btn-primary" onClick={(_e) => { this.applyNowFlow() }}
                    >
                        Apply Now
                    </button>
                  </div>

                  <div className='login-navigate-link'>
                    <Link to="/login" className='apply-important-info-link' 
                    onClick={() => analyticsClickEvent('login_hyperlink','')}>
                        Want to log in? Click here
                    </Link>
                  </div>

                  <div className="page-footer--application-landing">
                    <p className='text-center'>
                    humm is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478.
                    </p>
                  </div>
                </div>
                

             </div>

          </div>
          </div>
         );
    }
}


export const mapStateToProps = (state) => {
    return {
        applicationValidation:state.dataStore.applicationValidation,
        amountRequested:state.dataStore.amountRequested,
        saveApplicationData:state.dataStore.saveApplicationData,
    };
   };
  
  
  export  const mapDispatchToProps = (dispatch) => {
    return {
        appvalidations:(validations)=>{
            return dispatch({
                type: 'APP_VALIDATIONS_Remove',
                value: validations
            });
        },
        getAmountRequested:(validations)=>{
            return dispatch({
                type: 'AMOUNTREQUESTED',
                value: validations
            });
          },
          removeSavedata:(validations)=>{
            return dispatch({
                type: 'REMOVEDATA',
                value: validations
            });
          },
        
    }
   }
  
  export default connect(mapStateToProps,mapDispatchToProps)(ApplicationLanding);