import Cookies from 'universal-cookie';
import { frontEndLogs } from '../action/logs/frontEndLogs';

export const handlingSession = (statusCode) => {
    const cookie = new Cookies();
    let marketingConsent = {
        emailConsent : localStorage.getItem('emailConsent'),
        smsConsent : localStorage.getItem('smsConsent'),
        mobileConsent : localStorage.getItem('mobileConsent')
    }
    if(window.location.search.includes('_branch_referrer') && localStorage.getItem('storeID') !== null){
        // when the query string contains "_branch_referrer" that's related to Branch.io, save retailerId in the cookies

        cookie.set('retailerId',localStorage.getItem('storeID'),{ path: '/' });
    } else if(statusCode===408){
        frontEndLogs('When the session expires, obtain marketing consent information.'+ JSON.stringify(marketingConsent),false,200, 'handlingSession')
        // Remove cookie for Retailer Id once the user session is expired
        cookie.remove('retailerId', { path: '/' });
        cookie.remove('loanAmount', { path: '/' });
        cookie.remove('firstName', { path: '/' });
        cookie.remove('lastName', { path: '/' });
        cookie.remove('email', { path: '/' });
        cookie.remove('mobileNumber', { path: '/' });
        cookie.remove('term', { path: '/' });
        cookie.remove('sourceURL', { path: '/' });
        cookie.remove('approvedURL', { path: '/' });
        cookie.remove('retailerReference', { path: '/' });
        cookie.remove('Dob', { path: '/' });
        cookie.remove('flatAbode', { path: '/' });
        cookie.remove('houseandBuildingName', { path: '/' });
        cookie.remove('houseandBuildingNumber', { path: '/' });
        cookie.remove('numberAndStreet', { path: '/' });
        cookie.remove('addressLine2', { path: '/' });
        cookie.remove('townOrCity', { path: '/' });
        cookie.remove('postcode', { path: '/' });
    }
    

    if(localStorage.getItem('fromWhere')!='skip'){ 
        if(statusCode===408){
            frontEndLogs('When the session ends, gather marketing consent information based on fromWhere.'+ JSON.stringify(marketingConsent),false,200, 'handlingSession')
            window.location.href = "/session-expired";
            localStorage.clear();
        } 
    }else if(statusCode===408){
            frontEndLogs('When the session ends, gather marketing consent information without fromWhere.'+ JSON.stringify(marketingConsent),false,200, 'handlingSession')
            localStorage.setItem('showPopup','true');
            window.location.href = "/application";
           
    }  
}


export const sessionID = (val) =>{
    let DatatArray={};
    const cookie = new Cookies();
    DatatArray['Cache-control']='no-store';
    DatatArray['Pragma']='no-store';
    DatatArray['X-XSS-Protection']=`1 mode=block`; 
    DatatArray['X-Content-Type-Options']='nosniff';
    DatatArray['Referrer-Policy']='same-origin';
    DatatArray['Strict-Transport-Security']=`max-age=31536000; includeSubDomains; preload`
    DatatArray['X-DNS-Prefetch-Control']="on";
    DatatArray['X-Frame-Options']='sameorigin';
    DatatArray['Expect-Ct']='96400';
    DatatArray['csrf_token']=localStorage.getItem('csrfToken');
    DatatArray['apply_now'] = localStorage.getItem('fromWhere') === 'apply_now' ? true : "false";
    DatatArray['apple_user'] = cookie.get('userType') === 'Hide My Mail';
    DatatArray['application_channel']="Web";
    DatatArray['session_id']=localStorage.getItem('sessionId') ?? 'null';
    DatatArray['X-Session-ID']='';
    DatatArray['user_tracking_id']=localStorage.getItem('user_tracking_id') ?? '';
    // Cross-Origin-Embedder-Policy: unsafe-none | require-corp
    return DatatArray;
}
