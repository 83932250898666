import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {getAutoAddress} from '../../../action/autoAddress/getAutoAddress';
import {getFindAddress,getFindAddressSecondCall} from '../../../action/autoAddress/getFindAddress'
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMFieldInteraction, GTMFieldValidChange } from '../../../utils/GtmDatalayer';
import { PropTypes } from 'prop-types';
import Cookies from 'universal-cookie';
import { frontEndLogs } from '../../../action/logs/frontEndLogs';
import { removeSpecialCharacterInAddressFields } from '../../../utils/helpers/addressFieldValidation';

export class AutoAddress extends React.Component {
 constructor(props) {
   super(props);
   this.state = {
     suggestions: [],
     text:'',
     fields:{},
     error:{},
     changeText:false,
     showValidation:this.props.showAppValidations,
   }

   this.hasAnotherCall =this.hasAnotherCall.bind(this);
 }

 componentDidMount(){
  this.handlingValidation(this.state.text);
  this.addressPrePopulatedInPurchaseflow();
 }

  onBlurEvent = (e) => {
    const name = e.target.name
    if(name == 'Current_Address_Country__c'){
      GTMFieldValidChange('Current Address');
    }
    if(name == 'Previous_Address_Country__c'){
      GTMFieldValidChange('Previous Address')
    }
  }

  onTextChange = async(e) => {
    this.setState({changeText:true})
    const value = e.target.value;
    this.setState({text:value});
    this.setState({showValidation:'yes'});
    this.handlingValidation(value);
    const autoResult=  await getAutoAddress(value);
    
    var suggestNameList=[];
  if(autoResult.data.options){
    if(autoResult.data.totalOptions!==autoResult.data.options.length){
      if(autoResult.data.options[0]){
        if(autoResult.data.options[0].links[0].href){
          const getFindAddressData=await getFindAddressSecondCall(autoResult.data.options[0].links[0].href);
          getFindAddressData.data.options.map((lists) => {
            const datalists = [
              lists.displayName,
              lists.links[0].rel,
              lists.links[0].href
            ];
            suggestNameList.push(datalists);
          });
        }
      }
    }else{
      autoResult.data.options.map((list) => {
        const datalist = [
          list.displayName,
          list.links[0].rel,
          list.links[0].href
        ];
        suggestNameList.push(datalist);
      });
    }
  }
    this.setState({suggestions:suggestNameList});

  }

  handlingValidation=(value)=>{
    let stages={};
      stages[this.props.field_api_name] = ''; 
      stages['stage'] = this.props.stage; 
      this.props.appvalidations(stages);
  }
 
  async hasAnotherCall(NexResult){
    let finalResult =[];
    NexResult.data.options.map((item) => {
          const lists = [
            item.displayName,
            item.links[0].rel,
            item.links[0].href
          ];
          finalResult.push(lists);
        });
        return finalResult[0];
  }

  async suggestionSelected(value){
    let selectAddress=value[0];
    if(value){
      if(value[0] ){
        if(value[0].includes('addresses')){
          let suggestNameList=[];
          const getFindAddressDatas=await getFindAddressSecondCall(value[2]);
          getFindAddressDatas.data.options.map((listAddress) => {
            const datalists = [
              listAddress.displayName,
              listAddress.links[0].rel,
              listAddress.links[0].href
            ];
            suggestNameList.push(datalists);
          });
          this.setState({suggestions:suggestNameList});
          this.renderSuggestions();
          return false;
        }
      }
    }
   
      let autoFindResult=  await getFindAddress(value);
      //find reformat address details stats code is 500
      if(autoFindResult && autoFindResult.data.result.code=== 500){
        let nextLink = await this.hasAnotherCall(autoFindResult);
        autoFindResult= await getFindAddress(nextLink);
        selectAddress=autoFindResult.data.input.address;
      }
        let inputValue={}
        let address = selectAddress ?? '';
        let fields=this.state.fields;
        fields[this.props.field_api_name]=address;
        this.setState(()=>({
        text:address,
        fields,
        suggestions:[]
      }))
      inputValue[this.props.field_api_name] = address.toString();
      this.props.saveApplicationDatas(inputValue)
      this.props.fieldChange(this.props.field_api_name,address);
     
   
      if(autoFindResult){
        this.currentAddressPopulate(autoFindResult);
    }else{
      return '';
    }
  }

  currentAddressPopulate = (autoFindResult) => {
   
    if(autoFindResult.data.options && autoFindResult.data.result.code===100){
      let vanityAddressElements = autoFindResult['data']['vanityAddressElements'];
      frontEndLogs('Vanity Address Element from auto find address api',false,200, 'currentAddressPopulate',vanityAddressElements)
      let autoAddressResult ={};
      autoAddressResult['addressLineOneInfo']= autoFindResult.data.reformattedAddress[0]?autoFindResult.data.reformattedAddress[0]:null;
      autoAddressResult['addressLineTwoInfo']= autoFindResult.data.reformattedAddress[1]?autoFindResult.data.reformattedAddress[1]:null;
      autoAddressResult['addressLineThreeInfo']= autoFindResult.data.reformattedAddress[2]?autoFindResult.data.reformattedAddress[2]:null;
      autoAddressResult['townInfo']= autoFindResult.data.reformattedAddress[3]?autoFindResult.data.reformattedAddress[3]:null;
      autoAddressResult['countryInfo'] = autoFindResult.data.reformattedAddress[4]?autoFindResult.data.reformattedAddress[4]:null;
      autoAddressResult['postalCodeInfo']= autoFindResult.data.postcode?autoFindResult.data.postcode:null;
    
      this.fieldValidation(autoAddressResult,vanityAddressElements);
        
      }
  }
  

  addressPrePopulatedInPurchaseflow = async () => {
    const cookie = new Cookies();
    let prePopulatePurchaseAddressfieldValues = {};

    if (this.props.field_api_name === 'Current_Address_Country__c') {
      if (cookie.get('sourceURL')) {
        frontEndLogs('Purchase link cookie address params ', false, 200, 'addressPrePopulatedInPurchaseflow', { DOB: cookie.get('Dob'), street: cookie.get('numberAndStreet'), postcode: cookie.get('postcode'), townOrCity: cookie.get('townOrCity'), addressLine2: cookie.get('addressLine2') });
        prePopulatePurchaseAddressfieldValues = await this.vanityAddressPrePopulate(prePopulatePurchaseAddressfieldValues);
        const addressDetailsData = Object.assign({}, ...this.props.saveApplicationData);

        await this.otherVanityAddressPrePopulate(prePopulatePurchaseAddressfieldValues, cookie, addressDetailsData);
        await this.sendAddressChangepropsInPurchaseFlow();
      }
    }
  }

  otherVanityAddressPrePopulate = async (prePopulatePurchaseAddressfieldValues, cookie, addressDetailsData) => {
   
    if (cookie.get('townOrCity')) {
      addressDetailsData['Town__c'] = cookie.get('townOrCity');
      this.props.saveApplicationDatas(addressDetailsData);
      prePopulatePurchaseAddressfieldValues['Town__c'] = '';
    }
 
    if (cookie.get('postcode')) {
      addressDetailsData['Postal_Code__c'] = cookie.get('postcode');
      this.props.saveApplicationDatas(addressDetailsData);
      prePopulatePurchaseAddressfieldValues['Postal_Code__c'] = '';
    }

    if (cookie.get('addressLine2')) {
      addressDetailsData['Address_Line2__c'] = cookie.get('addressLine2');
      this.props.saveApplicationDatas(addressDetailsData);
      prePopulatePurchaseAddressfieldValues['Address_Line2__c'] = '';
    }
    this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
  }

    vanityAddressPrePopulate = async (prePopulatePurchaseAddressfieldValues) =>{
      const cookie = new Cookies();
      if (cookie.get('flatAbode')) {
        let flatAbode = {}
        const flatAbodeApplicationDetails = Object.assign({}, ...this.props.saveApplicationData);
        let cookiesFlatNumber = removeSpecialCharacterInAddressFields(flatAbode,'flatNumberInfo',cookie.get('flatAbode'),'autoAddressPrePopulate');
        if (cookiesFlatNumber) {
          flatAbodeApplicationDetails['Current_Flat_No__c'] = cookiesFlatNumber['flatNumberInfo'];
          this.props.saveApplicationDatas(flatAbodeApplicationDetails);
          prePopulatePurchaseAddressfieldValues['Current_Flat_No__c'] = '';
          this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
        }
        
      }

      if (cookie.get('houseandBuildingName')) {
        let houseName = {}
        const houseandBuildingNameApplicationDetails = Object.assign({}, ...this.props.saveApplicationData);
        let cookiesHouseName = removeSpecialCharacterInAddressFields(houseName,'buildingNameInfo',cookie.get('houseandBuildingName'),'autoAddressPrePopulate');
        if (cookiesHouseName) {
          houseandBuildingNameApplicationDetails['Current_House_Name__c'] = cookiesHouseName['buildingNameInfo'];
          this.props.saveApplicationDatas(houseandBuildingNameApplicationDetails);
          prePopulatePurchaseAddressfieldValues['Current_House_Name__c'] = '';
          this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
        }
      }

      if (cookie.get('houseandBuildingNumber')) {
        let buildingNumber = {}
        const houseandBuildingNumberApplicationDetails = Object.assign({}, ...this.props.saveApplicationData);
        let cookiesBuildingNumber = removeSpecialCharacterInAddressFields(buildingNumber,'buildingNumberInfo',cookie.get('houseandBuildingNumber'),'autoAddressPrePopulate');
        if (cookiesBuildingNumber) {
          houseandBuildingNumberApplicationDetails['HouseNumber__c'] = cookiesBuildingNumber['buildingNumberInfo'];
          this.props.saveApplicationDatas(houseandBuildingNumberApplicationDetails);
          prePopulatePurchaseAddressfieldValues['HouseNumber__c'] = '';
          this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
        }
      }

      prePopulatePurchaseAddressfieldValues = this.handleToSetExistingAddressFields(prePopulatePurchaseAddressfieldValues)
      return prePopulatePurchaseAddressfieldValues;
    }

    handleToSetExistingAddressFields = async(prePopulatePurchaseAddressfieldValues)=>{
      const cookie = new Cookies();
      if (cookie.get('numberAndStreet')) {
        let numberAndStreet = {}
        const numberAndStreetApplicationDetails = Object.assign({}, ...this.props.saveApplicationData);
        let cookiesNumberAndStreet = removeSpecialCharacterInAddressFields(numberAndStreet,'addressLineOneInfo',cookie.get('numberAndStreet'),'autoAddressPrePopulate');
        if (cookiesNumberAndStreet) {
          numberAndStreetApplicationDetails['Address_Line1__c'] = cookiesNumberAndStreet['addressLineOneInfo'];
          this.props.saveApplicationDatas(numberAndStreetApplicationDetails);
          prePopulatePurchaseAddressfieldValues['Address_Line1__c'] = '';
          this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
        }
      }

      if (cookie.get('addressLine2')) {
        let addressLineTwo = {}
        const addressLineTwoApplicationDetails = Object.assign({}, ...this.props.saveApplicationData);
        let updatedAddressLineTwoValue = removeSpecialCharacterInAddressFields(addressLineTwo,'addressLineTwoInfo',cookie.get('addressLine2'),'autoAddressPrePopulate');
        
        if (updatedAddressLineTwoValue) {
          addressLineTwoApplicationDetails['Address_Line2__c'] = updatedAddressLineTwoValue['addressLineTwoInfo'];
          this.props.saveApplicationDatas(addressLineTwoApplicationDetails);
          prePopulatePurchaseAddressfieldValues['Address_Line2__c'] = '';
          this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
        }
      }

      if (cookie.get('townOrCity')) {
        let townInfo = {}
        const townApplicationInputDetailsData = Object.assign({}, ...this.props.saveApplicationData);
        let updatedTownValue = removeSpecialCharacterInAddressFields(townInfo,'townInfo',cookie.get('townOrCity'),'autoAddressPrePopulate');
        if(updatedTownValue){
          townApplicationInputDetailsData['Town__c'] = updatedTownValue['townInfo'];;
        this.props.saveApplicationDatas(townApplicationInputDetailsData);

        prePopulatePurchaseAddressfieldValues['Town__c'] = '';
        this.props.appvalidations(prePopulatePurchaseAddressfieldValues);
        }
        
      }
      return prePopulatePurchaseAddressfieldValues;
    }
  
  sendAddressChangepropsInPurchaseFlow = async () => {
    const cookie = new Cookies();
    let currentAddressFieldsresults = {
      'FlatNumber__c': null,
      'HouseName__c': null,
      'HouseNumber__c': null
    };

    currentAddressFieldsresults = await this.vanityCurrentAddressPrePopulate(currentAddressFieldsresults);

    const fieldsToUpdate = {
      'Address_Line1__c': 'numberAndStreet',
      'Address_Line2__c': 'addressLine2',
      'Town__c': 'townOrCity',
      'Postal_Code__c': 'postcode'
    };

    Object.keys(fieldsToUpdate).forEach(field => {
      const cookieValue = cookie.get(fieldsToUpdate[field]);
      currentAddressFieldsresults[field] = cookieValue && cookieValue !== '' ? cookieValue : '';
    });


    this.props.addressChange(currentAddressFieldsresults);
  }

    vanityCurrentAddressPrePopulate = async(currentAddressFieldsresults)=>{
      const cookie = new Cookies();
      if (cookie.get('flatAbode')) {
        currentAddressFieldsresults['Current_Flat_No__c'] = cookie.get('flatAbode');
      } else {
        currentAddressFieldsresults['Current_Flat_No__c'] = '';
      }

      if (cookie.get('houseandBuildingName')) {
        currentAddressFieldsresults['Current_House_Name__c'] = cookie.get('houseandBuildingName');
      } else {
        currentAddressFieldsresults['Current_House_Name__c'] = '';
      }

      if (cookie.get('houseandBuildingNumber')) {
        currentAddressFieldsresults['HouseNumber__c'] = cookie.get('houseandBuildingNumber');
      } else {
        currentAddressFieldsresults['HouseNumber__c'] = '';
      }
      return currentAddressFieldsresults;
    }
  
   fieldValidation = async (autoAddressResult,vanityAddressElements) => {
    autoAddressResult = await this.handleVanityAddressElements(vanityAddressElements,autoAddressResult);
    if(this.props.field_api_name==='Current_Address_Country__c'){
  
      const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);
      let fieldValues = {}, stageThreeValues= {}
  
      if((this.props.prepopulateStatus) && allUserDetails['Current_Residential_Status__c'] ) {
        
        fieldValues['Current_Residential_Status__c'] = '';
        stageThreeValues['Current_Residential_Status__c'] = true;
      }
      this.props.appvalidations(fieldValues);
      this.props.thirdSectionValidationUpdate(stageThreeValues);
      let addressLineOne = removeSpecialCharacterInAddressFields(autoAddressResult,'addressLineOneInfo',autoAddressResult['addressLineOneInfo'],'autoAddressPrePopulate');
      let addressLineTwo = removeSpecialCharacterInAddressFields(autoAddressResult,'addressLineTwoInfo',autoAddressResult['addressLineTwoInfo'],'autoAddressPrePopulate');
      
      let currentAddressFieldsresults={};
      currentAddressFieldsresults['FlatNumber__c']=null;
      currentAddressFieldsresults['HouseName__c']=null;
      currentAddressFieldsresults['Address_Line1__c']=addressLineOne['addressLineOneInfo'];
      currentAddressFieldsresults['Town__c']=autoAddressResult['townInfo'];
      currentAddressFieldsresults['County__c']=autoAddressResult['countryInfo'];
      currentAddressFieldsresults['Postal_Code__c']=autoAddressResult['postalCodeInfo'];
      currentAddressFieldsresults['Address_Line2__c']=autoAddressResult['addressLineTwoInfo']===null?'':addressLineTwo['addressLineTwoInfo'];
      currentAddressFieldsresults['Current_Flat_No__c'] = autoAddressResult['flatNumberInfo'];
      currentAddressFieldsresults['Current_House_Name__c'] = autoAddressResult['buildingNameInfo'];
      currentAddressFieldsresults['HouseNumber__c'] = autoAddressResult['buildingNumberInfo'];
      frontEndLogs('After setting the Vanity Address Element in address fields', false, 200, 'fieldValidation', currentAddressFieldsresults)
      this.props.addressChange(currentAddressFieldsresults);
      let stages={}
      let inputValues={}
  
      Object.keys(currentAddressFieldsresults).forEach((key)=>{
      if(currentAddressFieldsresults[key] || currentAddressFieldsresults[key] === ''){
        stages[key]='';
        stages['stage'] = this.props.stage; 
        this.props.appvalidations(stages);
        inputValues[key] = currentAddressFieldsresults[key];
        this.props.saveApplicationDatas(inputValues)
        stages={};
        inputValues={};
  
        setTimeout(() => {
          const allInputDetails = Object.assign({}, ...this.props.applicationValidation);
  
            this.sectionValidation(key, allInputDetails);
        }, 300);
      }
      });
    }
   }

   sectionValidation = (key, allInputDetails) => {
    if(key == 'Address_Line1__c' || key == 'Town__c' ||
          key== 'Postal_Code__c') {
              let stagesValues={}
              if(allInputDetails[key] === '') {
  
                  stagesValues[key] = true;
                  this.props.thirdSectionValidationUpdate(stagesValues);
              }
              else {
                  stagesValues[key] = false;
                  this.props.thirdSectionValidationUpdate(stagesValues);
              }
          }
   }


  async handleVanityAddressElements(vanityAddressElements, autoAddressResult) {
    if (vanityAddressElements && vanityAddressElements.length > 0) {
      let priorityMap = {
        "PostTown": 1,
        "City": 2,
        "Town": 3,
        "Village": 4,
        "Locality": 5
      };

      let highestPriority = 6; // Higher than any valid priority
      let selectedTownInfo = null;
      autoAddressResult['flatNumberInfo'] = '';
      autoAddressResult['buildingNameInfo'] = '';
      autoAddressResult['buildingNumberInfo'] = '';
      vanityAddressElements.map((vanityAddress) => {
        if (vanityAddress?.type?.text === "AddressPointName") {
          let flatNumberRemovedSpecialCharacter = removeSpecialCharacterInAddressFields(autoAddressResult,'flatNumberInfo',vanityAddress?.value,'autoAddressPrePopulate');
          
          autoAddressResult['flatNumberInfo'] = flatNumberRemovedSpecialCharacter['flatNumberInfo'];
        }

        if (vanityAddress?.type?.text === "BuildingName") {
          let buildingNameRemoveSpecialCharacter = removeSpecialCharacterInAddressFields(autoAddressResult,'buildingNameInfo',vanityAddress?.value,'autoAddressPrePopulate');
          autoAddressResult['buildingNameInfo'] = buildingNameRemoveSpecialCharacter['buildingNameInfo'];
        }

        if (vanityAddress?.type?.text === "BuildingNumber") {
          let buildingNoRemoveSpecialCharacter = removeSpecialCharacterInAddressFields(autoAddressResult,'buildingNumberInfo',vanityAddress?.value,'autoAddressPrePopulate');
          autoAddressResult['buildingNumberInfo'] = buildingNoRemoveSpecialCharacter['buildingNumberInfo'];
        }

        if (vanityAddress?.type?.text === "Thoroughfare") {
          let addressLineOneRemoveSpecialCharacter = removeSpecialCharacterInAddressFields(autoAddressResult,'addressLineOneInfo',vanityAddress?.value,'autoAddressPrePopulate');
          autoAddressResult['addressLineOneInfo'] = addressLineOneRemoveSpecialCharacter['addressLineOneInfo'];
        }

        if (priorityMap[vanityAddress?.type?.text] && priorityMap[vanityAddress.type.text] < highestPriority) {
          highestPriority = priorityMap[vanityAddress.type.text];
          selectedTownInfo = vanityAddress?.value;
        }

      })
      if (selectedTownInfo) {
        let townInfoRemoveSpecialCharacter = removeSpecialCharacterInAddressFields(autoAddressResult,'townInfo',selectedTownInfo,'autoAddressPrePopulate');
        autoAddressResult['townInfo'] = townInfoRemoveSpecialCharacter['townInfo'];
      }
    }

    return autoAddressResult;
  }

    componentWillReceiveProps(nextProps) {
      if(nextProps.showCurrentAddress!=''){
        console.log('nextProps.showCurrentAddress',nextProps.showCurrentAddress)
        this.props.allowManually(nextProps.showCurrentAddress)
      }
      if(nextProps.prepopulateStatus===true && this.state.changeText===false){
        this.props.allowManually(this.props.field_api_name)
        const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
        this.setState({ text:allDetailData[this.props.field_api_name] })
      }
      if (nextProps.showAppValidations !== this.state.showValidation) {
        if(nextProps.showAppValidations!==''){
         this.setState({ showValidation:nextProps.showAppValidations })
        }
      } 
    }
      
 
 renderSuggestions = () => {
  const { suggestions } = this.state;
  if (suggestions.length === 0) {
    return null;
  }
  return (
    <ul className="custom-list">
      {suggestions.map(itemList => <li key={itemList} onClick={(e)=>this.suggestionSelected(itemList)}>
        {itemList[0]}</li>)}
    </ul>
  )
}

onFocus = (e) => {
  e.target.setAttribute('autoComplete', 'nope')

  if(e.target.name === 'Current_Address_Country__c'){
    GTMFieldInteraction(this.props.field_label);
  }

  if(e.target.name === 'Previous_Address_Country__c'){
    GTMFieldInteraction(this.props.field_label);
  }
}
 
 onClickEventForShowValidation= (event) => {
  analyticsClickEvent('enter_manually','');
  event.preventDefault();
  this.props.allowManually(this.props.field_api_name)
 }

 render() {
  let disableValue=''
  if(this.props.editable===false && this.props.prepopulateStatus===true && this.props.prepopulateCondition===true){
    disableValue='nonEditable'
  }
  const allDetails = Object.assign({}, ...this.props.applicationValidation);
  
   const {text}=this.state
   return (
    <>
    <div className="dynamic-input">
      <div className="form-group"> 
      <div className='d-flex flex-row'>
        <span>{this.props.field_question}
        {(this.props.flxQuestionMoreInfo) &&
          <a className='fieldTip'>
            <span>
                <img src="images/humm-infoIcon.svg" alt="humm-logo" />
                {this.props.flxQuestionMoreInfo}
            </span>
            <img src="images/more-info-icon.svg" alt="info-icon" />
          </a>
        }
        </span>
      </div>

   <div className={`TypeAheadDropDown ${disableValue}`}>

                  <input type="text" name={this.props.field_api_name} value="" readOnly={true} style={{display: "none",opacity: 0,position: "absolute"}} autoComplete="off"/>
             
     <input className="form-control" 
    onChange={this.onTextChange}
     name={this.props.field_api_name}
     placeholder={this.props.field_placeholder} 
     value={this.state.text} type="text" onBlur={this.onBlurEvent}  onFocus={this.onFocus} autoCorrect="off"  autoCapitalize="none" spellCheck="false" onClick={(e)=>analyticsClickEvent(this.props.field_label,'')}/>

     {text && 
     this.renderSuggestions()
     }
     {allDetails[this.props.field_api_name]!== '' && this.state.showValidation==='yes' && 
                                            <p className="error-message"><span className="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span> </p>
                                        }
   </div>
   </div>
   </div>
   <div className="dynamic-input">
      <div className="form-group"> 
        <div className='TypeAheadDropDown address-hyperlink'>
          {this.state.showValidation==='yes' &&
            <Link className= {`manual-address  ${allDetails[this.props.field_api_name]!==''?"address-right":""}`} 
            onClick={this.onClickEventForShowValidation}>Enter manually instead</Link>
          }
          {this.state.showValidation==='' &&
            <Link className="manual-address" onClick={this.onClickEventForShowValidation}>Enter manually instead</Link>
          }
        </div>
      </div>
   </div>
    </>
   );
 }
 
}
export const mapStateToProps = (state) => {
  return {
      applicationValidation:state.dataStore.applicationValidation,
      showAppValidations:state.dataStore.showAppValidations,
      saveApplicationData:state.dataStore.saveApplicationData,
      prepopulateStatus:state.dataStore.prepopulateStatus,
      questionValueSave:state.dataStore.questionValueSave,
      showCurrentAddress: state.dataStore.showCurrentAddress,
      thirdSectionSteps: state.dataStore.thirdSectionSteps
  };
 };


export  const mapDispatchToProps = (dispatch) => {
  return {
      appvalidations:(validations)=>{
          return dispatch({
              type: 'APP_VALIDATIONS',
              value: validations
          });
      },
      saveApplicationDatas:(data)=>{
        return dispatch({
          type: 'SAVEDATA',
          value: data
      });
      },
      questionSave:(data)=>{
        return dispatch({
          type: 'QUESTIONSAVE',
          value: data
      });
      },
      thirdSectionValidationUpdate: (validations) => {
        return dispatch({
          type: "THIRD_SECTION_STEPS",
          value: validations
        });
      },
  }
 }

AutoAddress.propTypes = {
  applicationValidation: PropTypes.array,
  field_api_name: PropTypes.string,
  stage: PropTypes.string,
  showAppValidations: PropTypes.string,
  appvalidations: PropTypes.func,
  saveApplicationDatas: PropTypes.func,
  fieldChange: PropTypes.func,
  addressChange: PropTypes.func,
  thirdSectionValidationUpdate: PropTypes.func,
  showCurrentAddress: PropTypes.string,
  allowManually: PropTypes.func,
  prepopulateStatus: PropTypes.bool,
  field_label: PropTypes.string,
  editable: PropTypes.bool,
  prepopulateCondition: PropTypes.bool,
  flxQuestionMoreInfo: PropTypes.string,
  field_placeholder: PropTypes.string,
  saveApplicationData: PropTypes.array,
  field_question: PropTypes.string,
}

export default connect(mapStateToProps,mapDispatchToProps)(AutoAddress)


