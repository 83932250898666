import React from "react";
import {forgotPasswordCode} from "../../../action/auth/forgotPasswordCode";
import { Redirect } from 'react-router-dom';
import {manualLogin} from "../../../action/auth/login";
import { connect } from "react-redux";
import {validatePasswordField} from '../../validation/passwordValidation';
import { analyticsClickEvent, } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import parse from 'html-react-parser';
import { extractEmail } from "../../../utils/extractEmail";
import {PropTypes} from 'prop-types';
import Cookies from "universal-cookie";
import { frontEndLogs } from "../../../action/logs/frontEndLogs";

export class ForgotPasswordCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          newPassword:'',
          confirmPassword:'',
          disable:'',
          code:'',
          errors:{
            newPasswordError:'',
            codeError:'',
            emailError: ''
          },
          email: '',
          emailDisabled: false,
          serverErrorMessage: '',
          redirect:false,
          serverError:'',
          serverErrorColor:'',
          serverMessage:'',
          inputStateDisabled: false,
          clientErrorMessage: '',
          eventState: '',
        };
          this.handleChange = this.handleChange.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
      }

    componentDidMount(){
        if(localStorage.getItem('forgotEmail')) {
            this.setState({ email: localStorage.getItem('forgotEmail') })
        }
    }
         
  handleChange(event) {
    
    const target = event.target;
    const name = target.name;
    let value = target.value;
    if(name==="code"){
        value = target.value.replace(/\D/g, "");
    }
    
    this.setState({ [name]: value});

   
    let server=this.state;

    switch(name){
        case 'newPassword':
            server = {...server,errors:{...server.errors,newPasswordError: validatePasswordField(value)}};
            break;
            case 'code':
            if (value.trim() === "") {
                server = {...server,errors:{...server.errors,codeError:'Code is required'},serverError:''};
            }else if(value.trim().length > 5){
                server = {...server,errors:{...server.errors,codeError:'Code does not accept more than 5 digits'},serverError:''};
            }else{
                server = {...server,errors:{...server.errors,codeError:''},serverError:''};
            }
         
            break;
            default:
            server = {...server,errors:{codeError:'',newPasswordError:''}};
    }  
    console.log('newPasswordError',server); 
    frontEndLogs('password error message'+JSON.stringify(this.state.errors),false,400, 'handleChange',server)
    server={...server,[name]:value};
    this.setState({
        errors: {
          ...this.state.errors,
          newPasswordError: '',
        },
      },()=>{
        console.log('newPasswordError_state_error',this.state.errors);
        this.setState({...server});
      });
    
  }


  fieldValidationForEmail = (emailValue) => {
    let errors = this.state.errors;

    if(extractEmail(emailValue) !== null) {
        errors.emailError='';
    }else if (emailValue.trim() === "") {
        errors.emailError = "Email is required";
    }else{
        errors.emailError= "Please enter a valid email";
    }

    this.setState({ errors });
  }

  handleSubmitForEmailInputPopup = () => {
    this.fieldValidationForEmail(this.state.email);
    this.setState({serverErrorMessage: ''});

    if(this.state.errors.emailError !== '') {
        return false;
    }
    this.setState({emailDisabled: true})
    
    this.handleSubmit(this.state.eventState);
  }


  initialFieldValidation(newPassword,verifyCode){

    let errors = this.state.errors
    if(newPassword.trim()===''){
        errors.newPasswordError='New Password is required';
        this.setState({errors});
    }
    if(verifyCode.trim()===''){
        errors.codeError='Code is required';
        this.setState({errors:errors,serverError:''});
    }
  }

   handleSubmit=async(event)=> {
    analyticsClickEvent('forgotPasswordCode_confirm','')
    event.preventDefault();
    this.initialFieldValidation(this.state.newPassword,this.state.code);
    
    if(this.state.errors.newPasswordError || this.state.errors.codeError){
        return false
    }

        if(!(this.state.email)) {
            this.setState({eventState: event});

            window.$('#forgotPasswordEmailInputModal').modal('show');
            return false
        }
        const cookie = new Cookies()
        const obj={
            'email':localStorage.getItem('forgotEmail') ?? this.state.email,
            'verify_code':this.state.code,
            'new_password':this.state.newPassword,
            'Application_Channel': "Web"
        }
        if(cookie.get('userType') === 'Hide My Mail'){
            obj['Mobile_Number'] = obj['Mobile_Number'] = this.props.appleUserMobileNumber ? this.props.appleUserMobileNumber : cookie.get('apple_user_mobile_number');
        }


        this.setState({disable:'disabled', inputStateDisabled: true});
      
        const forgotPasswordResult = await forgotPasswordCode(obj);

        // To retry API call when the data is empty after 20 seconds showing the error popup
        setTimeout(() => {
            if(forgotPasswordResult.data == undefined) {
                this.setState({eventState: event, clientErrorMessage: 'Something went wrong. Try Again'})
                window.$('#forgotPasswordErrorModal').modal('show');
            }
        }, 20000);

        if(forgotPasswordResult.status===200){
            this.handleSuccessResponseOfForgotPasswordCode(forgotPasswordResult)
        }
        else if(forgotPasswordResult?.data?.data?.reason === 'INVALID_EMAIL') {
            this.setState({eventState: event});
            if (window.$('#forgotPasswordEmailInputModal').hasClass('show') === true) {
                this.setState({ emailError: '', serverErrorMessage: forgotPasswordResult?.data?.message, emailDisabled: false })
                return false;
            }
            window.$('#forgotPasswordEmailInputModal').modal('show');
        }
        else if(forgotPasswordResult?.data?.message.includes("Account not found")) {
            this.setState({ serverErrorMessage: forgotPasswordResult?.data?.message, emailDisabled: false })
        }
        else{
                this.handleFailureResponseOfForgotPasswordCode(forgotPasswordResult);
            }

        }

    backgroundImageDisplay=()=>{
        return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
                    <div className="leftLogin-img-sec">
                        <img src="images/humm-login-bg.jpg" alt="Background" />
                    </div>
                </div>;
    }

    handleFailureResponseOfForgotPasswordCode = (forgotPasswordResult) => {
        if (window.$('#forgotPasswordEmailInputModal').hasClass('show') === true) {
            window.$('#forgotPasswordEmailInputModal').modal('hide');
        }
        if(forgotPasswordResult.data.message == "Please use the password which recently not used"){
            this.setState({errors:{ newPasswordError: forgotPasswordResult.data.message}})
        }
        else{
        this.setState({serverError: forgotPasswordResult.data.message,errors:{...this.state.errors,codeError:''}})
        }
        this.setState({serverErrorColor:'red',inputStateDisabled: false})
        this.setState({disable:''});
    }

    // handle success response of API call 'user/password-change'
    handleSuccessResponseOfForgotPasswordCode = async(forgotPasswordResult) => {
        if (window.$('#forgotPasswordEmailInputModal').hasClass('show') === true) {
            window.$('#forgotPasswordEmailInputModal').modal('hide');
        }

        this.setState({serverMessage: forgotPasswordResult.data.message})
        const objs={
            'email':localStorage.getItem('forgotEmail') ?? this.state.email,
            'password':this.state.newPassword
           }

        const loginResult = await this.props.manualLogin(objs);
        if(loginResult.status===200){
            localStorage.setItem('UserName',loginResult.data.data.user_details.username);
            localStorage.setItem('token',loginResult.data.data.access_token);
            localStorage.setItem('sessionId',loginResult.data.data.session_id);
            
            localStorage.setItem('email', loginResult.data.data.user_details.email);
            localStorage.setItem('csrfToken',loginResult.data.data.csrf_token);

            if(loginResult.data.data.user_details.userid !== '' && loginResult.data.data.user_details.userid != null && 
            loginResult.data.data.user_details.userid != undefined){
                localStorage.setItem('userID', loginResult.data.data.user_details.userid);
            }
            setTimeout(() => {
                this.setState({redirect: true});
            }, 300);
        }
    }

    render(){
        if (this.state.redirect) {
            return <Redirect push to="/welcome" />;
        }
        return(
            <div className="layout-fixed">
            <div className="wrapper login-page scroll-sec-fix">
                    {this.backgroundImageDisplay()}
                    <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                        <div className="section-form">
                            <div className="form-header">
                                <span className="section-logo">
                                    <img src="images/logo.svg" alt="logo"/>
                                </span>
                                <span className="section-heading">
                                    <h4>Ok great. Please enter your new <br></br>password below</h4>
                                </span>
                            </div>
                            <div className="form-body">
                                <form id="login-form" className="log-form forgot-password-code" name="loginForm">
                                    <div className="error-user">
                                        <div className="form-group floating-label-group">
                                            <input className="form-control"  min="0" id="code" name="code" placeholder=" "
                                                value={this.state.code} autoFocus required disabled={this.state.inputStateDisabled}
                                                 onChange={this.handleChange} onClick={(e) => analyticsClickEvent('forgotPasswordCode_code','')}/> 
                                                <label className="floating-label">Code</label>                     
                                        </div> 
                                        <p className="error-message code-error">
                                            {this.state.commonError !== '' && this.state.errors.codeError === '' &&
                                            <span style={{ color: 'Red' }}>{this.state.commonError}</span>
                                            }
                                            {this.state.errors.codeError !== '' &&
                                            <span style={{ color: 'Red' }}>{this.state.errors.codeError}</span>
                                            }
                                            {this.state.serverError !== '' && 
                                             <span style={{ color: 'Red' }}>{this.state.serverError }</span>
                                            }
                                            
                                        </p>
                                     
                                    </div>
                                    <div className="error-user psw-error">
                                        <div className="form-group floating-label-group">
                                            <input type="password" className="form-control"id="newPassword" name="newPassword" placeholder=" "
                                                value={this.state.newPassword} disabled={this.state.inputStateDisabled}
                                                required onChange={this.handleChange} onClick={(e) => analyticsClickEvent('new_password','')}/>  
                                                <label className="floating-label">New Password</label>
                                        </div>
                                        <p className="error-message code-error">
                                            {this.state.errors.newPasswordError !== '' &&<span style={{ color: 'Red' }}>
                                            {this.state.errors.newPasswordError}</span>}
                                            {this.state.serverMessage!== '' && 
                                             <span style= {{ color: 'green' }}>{this.state.serverMessage }</span>
                                            }
                                           
                                            
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" 
                                        className={`btn btn-warning form-control h-100 
                                        ${this.state.inputStateDisabled ? 'disable-color-btn' : ''}`} 
                                        onClick={this.handleSubmit} 
                                        disabled={this.state.inputStateDisabled}
                                        id="submit">Confirm</button>
                                    </div>                    
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
            {this.state.clientErrorMessage !== '' &&
                <div className="modal fade model-error" id="forgotPasswordErrorModal" tabIndex="-1" aria-labelledby="forgotPasswordErrorModalLabel" aria-hidden="true" data-backdrop="static"
                data-keyboard="false">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                                </div>
                                <p className="mt-4">{parse(this.state.clientErrorMessage)}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary"
                                onClick={()=> {
                                    window.$('#forgotPasswordErrorModal').modal('hide')
                                    this.handleSubmit(this.state.eventState) }
                                }>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            <div className="modal fade model-error" id="forgotPasswordEmailInputModal" tabIndex="-1" aria-labelledby="forgotPasswordEmailInputLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="logo">
                                <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                            </div>
                            <h4 className="modal-title mb-3" id="forgotPasswordEmailInputLabel">
                                Please reconfirm your email address
                            </h4>
                            
                            <div className="log-form">
                                <div className="error-user">
                                    <div className="form-group mt-4 mb-1">
                                        <input type="email" className="form-control" id="email" name='email'  value={this.state.email} placeholder="Email" onChange={this.handleChange} disabled={this.state.emailDisabled}
                                        style={{background: "#f4f4f4"}}
                                        onClick={(e)=>analyticsClickEvent('forgot_password_email','')}/>
                                    </div>
                                    <p className={`error-message code-error 
                                        ${this.state.errors.emailError !== '' || this.state.serverErrorMessage !== '' 
                                        ? 'mt-3 mb-4': ''} `}>
                                            {this.state.errors.emailError !== '' &&
                                                <span style={{ color: 'Red' }}>
                                                    {this.state.errors.emailError}
                                                </span>
                                            }
                                            {this.state.serverErrorMessage !== '' && 
                                                <span style={{ color: 'Red' }}>
                                                    {this.state.serverErrorMessage}
                                                </span>
                                            }  
                                    </p>
                                </div>

                                <div className="form-group mt-4 mb-4 text-center">
                                    <button type="button" className="btn btn-primary" style={{borderRadius: "9px", textTransform: "none"}}
                                    onClick={() => this.handleSubmitForEmailInputPopup()} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}

export const mapStateToProps = (state) => {
    return {
        loginStore: state.dataStore.loginStore,
        appleUserMobileNumber: state.dataStore.appleUserMobileNumber
    };
   };


 export  const mapDispatchToProps = (dispatch) => {
    return {
        manualLogin: (formData) => {
            return dispatch(manualLogin(formData));
        },
    }
   }

ForgotPasswordCode.propTypes = {
    manualLogin: PropTypes.func,
    appleUserMobileNumber: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordCode);
