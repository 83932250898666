import React, { Component } from "react";
import { Link } from "react-router-dom";
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import { frontEndLogs } from "../../action/logs/frontEndLogs";
import Instructions from "./instuctions/instruction";
import { PropTypes } from 'prop-types';
import Cookies from "universal-cookie";

class DocumentVerify extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      documentSteps: false,
      passportInstruction: false,
      drivinglicenseInstruction: false,
      selfieInstruction: false,
      documentType: '',
      instruction: true,
      instructionsSection: false,
      passportSection: false,
      drivinglicenseSection: false,
      isEnableDocumentsTabs:false,
      isSelectDocument:'',
      userLoginType:localStorage.getItem('userLoginType'),
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.dataLayer = window.dataLayer || {};
    let applicationId = this.getApplicationIDInstorage();
    if(applicationId !== '' && applicationId !== null && applicationId !== undefined){
      this.sendDeviceDetect();
    }
    if (this.props.refData) {
      this.setState({ documentSteps: true, instruction: false })
    }

    if(applicationId !== '' && applicationId != null && applicationId !== undefined){
      window.dataLayer.push({
          "event" : "metadata",
          "applicationID" : applicationId
      });
    }
    frontEndLogs('All available document are showed in document verify'+applicationId,false,200,'componentDidMount');
  }

  sendDeviceDetect = async() => {
    await frontEndLogs('Mitek process in mobile browser - '+this.getApplicationIDInstorage(),false,200,'get_mitek_document_response');
  }


  openinstruction = (documenttype) => {
    if(documenttype === 'passport'){
      analyticsClickEvent('passport','')
    }else if(documenttype === 'ID'){
      analyticsClickEvent('driving_licence','')
    }else if(documenttype === 'VETERAN_CARD'){
      analyticsClickEvent('hm_forces_card','')
    }
      //  analyticsClickEvent('documentType_in_'+documenttype,'')
    this.setState({isEnableDocumentsTabs:true,isSelectDocument:documenttype})

  }
  handleChange() {
    this.setState({isEnableDocumentsTabs:false,isSelectDocument:''})
    this.props.handleViewChange('4');
  }

  handleLoaderStatus = (value) => {
    this.setState({isLoading: value})
  }

  handleRetryLoaderStatus = (value) => {
    console.log('handleRetryLoaderStatus',value);
    this.props.handleRetryLoader(value);
  }

  passportInstructionOpenMobile = () => {
    this.openinstruction('passport')
    frontEndLogs('The user selected the passport document '+this.getApplicationIDInstorage(),false,200,'passportInstructionOpenMobile');
  }

  IDInstructionOpenMobile = () => {
    this.openinstruction('ID')
    frontEndLogs('The user selected the Driving License document '+this.getApplicationIDInstorage(),false,200,'IDInstructionOpenMobile');
  }

  veteranInstructionOpenMobile = () => {
    this.openinstruction('VETERAN_CARD')
    frontEndLogs('The user selected the veteran card document '+this.getApplicationIDInstorage(),false,200,'veteranInstructionOpenMobile');
  }

  getApplicationIDInstorage(){
    const cookie = new Cookies();
    return localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId')
  }

  render() {
    return (
      <div className="step-final">
        {this.state.isEnableDocumentsTabs === false &&
          <div className="mit-container">
            <p className="mit-mobile-app">Finally, let’s upload your ID. Please select and upload one of the documents listed below.</p>
            <div className="mit-document-section">
              <div className="mit-inner-box">
                <Link to="#" onClick={this.passportInstructionOpenMobile}>
                  <span className="inner-left">
                    <img src="images/passport.svg" alt="Card" />
                  </span>
                  <span className="inner-center">
                    <p>Passport</p>
                  </span>
                  <span className="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div className="mit-inner-box">
                <Link to="#" onClick={this.IDInstructionOpenMobile}>
                  <span className="inner-left">
                    <img src="images/driving-license.svg" alt="Card" />
                  </span>
                  <span className="inner-center">
                    <p>Driving Licence</p>
                  </span>
                  <span className="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
              <div className="mit-inner-box">
                <Link to="#" onClick={this.veteranInstructionOpenMobile}>
                  <span className="inner-left">
                    <img src="images/driving-license.svg" alt="Card" />
                  </span>
                  <span className="inner-center">
                    <p>HM Forces Card</p>
                  </span>
                  <span className="inner-right">
                    <img src="images/circle-outline.png" alt="Success" />
                  </span>
                </Link>
              </div>
            </div>

          </div>
        }
        {this.state.isEnableDocumentsTabs === true &&
          <Instructions documentType = {this.state.isSelectDocument} ref={this.child} 
          handleLoader={this.handleLoaderStatus} 
          handleRetryLoader={this.handleRetryLoaderStatus}
          handleChnage={this.handleChange} fromWhere={'application'}/>
        }
        
      </div>


    )
  }

}

DocumentVerify.propTypes = {
  refData: PropTypes.object,
  handleViewChange: PropTypes.func,
  handleRetryLoader : PropTypes.bool
}

export default DocumentVerify;
