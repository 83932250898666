
import './App.css';
import Routes from './routes';
import React, { useEffect, useState } from 'react';
import Loader from './component/loader/loader';

function App() {
  const [showContent, setShowContent] = useState(false);
  const [retailerReceived, setRetailerReceived] = useState(false);

  useEffect(() => {    
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); 
    let yyyy = today.getFullYear();
    let todayDate = mm + dd + yyyy;
  
    const hours = today.getHours(); 
    const minutes = today.getMinutes(); 
    const seconds = today.getSeconds();  
    const currentTimestamp = todayDate + hours + minutes + seconds
  
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "css/custom-style.css?v=" +currentTimestamp;
    link.type = "text/css";

    link.addEventListener('load', () => {
      setShowContent(true);
    })
    link.removeEventListener('load', () => {
      document.getElementsByTagName("head")[0].removeChild(link)
    })
    document.getElementsByTagName("head")[0].appendChild(link);

    setTimeout(() => {
      setRetailerReceived(true)
    }, 15000);
    
  }, []);
  

  console.log('loca_path_name',window.location.pathname);
  console.log('showContent_va',showContent);
  console.log('retailerReceived',retailerReceived);

  return (
    <div>
     {showContent ? <Routes /> : <Loader loaderStatus={true} />}
    </div>
    
  );
  
}

export default App;
