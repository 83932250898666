import React from 'react';
import {addFavoritePartner} from '../../action/partner/addFavoritePartner';
import {removeFavoritePartner} from '../../action/partner/removeFavoritePartner';
import {addViewPartner} from '../../action/partner/addViewPartners';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { Link } from 'react-router-dom';
import { thirdPartyWebsiteAlertPopupText } from '../../utils/const';

class PartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            value:'',
            isFavAdd:'',
            isFavRemove:'',
            itemList:[],
            partnerID: '',
        }
        this.handleFavoriteAdd = this.handleFavoriteAdd.bind(this)
        this.handleFavoriteRemove = this.handleFavoriteRemove.bind(this)
    }
    componentDidMount(){
        this.setState({itemList:this.props.itemList});
    }

    
    async handleFavoriteAdd(value,partnerName){
        let splitPartnerName = partnerName;
        if(partnerName != undefined){
            splitPartnerName = partnerName.split(' ').join('_');
        }
    // analyticsClickEvent(splitPartnerName+'_partner_image_favorite_icon');
    analyticsClickEvent('favorite_heart_checked',splitPartnerName);
        const addDataParam={email:localStorage.getItem('email'),account:value};
        const addFavoriteResponse = await addFavoritePartner(addDataParam);
        const tempData = addFavoriteResponse.data;
        let currentState = this.state.itemList;
        let addFavUpdated = currentState.map((item)=>{
            if(item.partnerId === tempData.account){
                item.partnerFavId = tempData.id
            
            }
            return item;
        })

         this.setState({itemList:addFavUpdated});
         this.props.favCall();
       
    }

    async handleFavoriteRemove(value,partnerName){
        let splitPartnerName = partnerName;
        if(partnerName != undefined){
            splitPartnerName = partnerName.split(' ').join('_');
        }
        // analyticsClickEvent(splitPartnerName+'_partner_image_unfavorite_icon');
        analyticsClickEvent('favorite_heart_unchecked',splitPartnerName);
        const removeDataParam={email:localStorage.getItem('email'),account:value};

        await removeFavoritePartner(removeDataParam);

        let currentState = this.state.itemList;
        let removeFavUpdated = currentState.map((item)=>{
            if(item.partnerId === value){
                item.partnerFavId = null
            
            }
            return item;
        })

         this.setState({itemList:removeFavUpdated});
         this.props.favCall();
     }

     addViewedPartner= (partnerID,partnerName,partnerWebsitLink)=>{
        if(partnerWebsitLink == '' || partnerWebsitLink == null || partnerWebsitLink == undefined){
            return false;
        }
        let splitPartnerName=partnerName;
        if(partnerName != undefined){
            splitPartnerName = partnerName.split(' ').join('_');
        }

        analyticsClickEvent('shop_partner',splitPartnerName);

        const resultPartnerURL = this.checkURL(partnerWebsitLink);
        this.setState({partnerID: partnerID}, () => {
          localStorage.setItem("partnerWebsiteURL", resultPartnerURL);
          setTimeout(() => {
            window.$('#thirdPartyWebsiteModel').modal('show');
          }, 200);
        });
     }

     partnerFilter=()=>{
        this.setState({itemList:this.props.itemList});
     }

     redirectionThirdPartnerSite = async () => {
        if(localStorage.getItem('fromWhere') != 'apply_now') {
          const requestObject = {
            email: localStorage.getItem('email'),
            account: this.state.partnerID
          }
          await addViewPartner(requestObject);
        }
        
        if(localStorage.getItem("partnerWebsiteURL")) {
          window.open(localStorage.getItem("partnerWebsiteURL"), '_blank', 'noopener noreferrer');
          window.$('#thirdPartyWebsiteModel').modal('hide');
        }
    
    }

     checkURL=(url)=>{
         if(url!=null && url!=undefined && url!=''){
            let pattern = /^((http|https|ftp):\/\/)/;

            if(!pattern.test(url)) {
             return "http://" + url;
            }else{
             return url;
            }
         }else{
            return url 
         }
        
     }

    currentLocationPathname = () => {
        localStorage.setItem('prevRoute',window.location.pathname)
    }
    
     
    render() { 
        
        return ( 
            <>
            <div className="gallery-partner">
            { this.state.itemList?.map(response =>  
                <div key ={response.partnerId} className="featured-box partner-item" >
            <div id={response.partnerId} className="featured-itemQr">
                <div className="partner-list-section">
                <a target rel="noreferrer" href="javascript:void(0)" onClick={()=>this.addViewedPartner(response.partnerId,response.partnerName,response.partnerWebSite)}>
                    <div className="partner-img">{response.partnerCoverImage==undefined &&
                      <img src="images/compu.png" alt={response.partnerName} />
                }
                {response.partnerCoverImage!=undefined &&
                      <img src={response.partnerCoverImage} alt={response.partnerName} 
                      onError={(evt) => evt.target.src = "images/partner-placeholder.png"} />
                }</div>

                    <div className="overlay"></div>
                </a>
                {localStorage.getItem('fromWhere')!='skip' &&
                <div className="fav-icon-section">
                {this.state.inputType &&(<span className="view-icon"  onClick={this.inputChangeHandler}><img src="images/eye-icon.svg" alt="Eye" /></span>)}
                               
                    {response.partnerFavId===undefined &&(<a value={response.partnerId}  onClick={e => { this.handleFavoriteAdd(response.partnerId,response.partnerName).catch(error => console.error(error)) } } className='fav-icon-btn'><img src="images/heart-white.svg" alt="Fav" /></a>) }
                    {response.partnerFavId!==undefined &&(<a value={response.partnerId} onClick={e => { this.handleFavoriteRemove(response.partnerId,response.partnerName).catch(error => console.error(error)) }} className='fav-icon-btn active'><img src="images/heart.svg" alt="Fav-active" /></a>) }
                    
                    
                </div>}
                <a target rel="noreferrer" href="javascript:void(0)" onClick={()=>this.addViewedPartner(response.partnerId,response.partnerName,response.partnerWebSite)}>                        
                    <div className="overlay-logo-item">
                        <span className="featured-caption">
                            <div className="slider-logo-image">
                                {response.partnerLogo != null &&
                                   <img src={response.partnerLogo} alt={response.partnerName}/>
                                }
                                {response.partnerLogo == null &&
                                    <h6 >{response.partnerName}</h6>
                                }
                            </div>
                        </span>
                    </div>
                </a>
                <div className="qtwo-calculator">
                    <Link
                        to={{
                            state: { id: response.partnerId, store_Name: response.partnerName },
                            pathname: "/quote-calculator",
                        }} onClick={this.currentLocationPathname}
                    >
                        <button className='qc-click-btn'
                        onClick={(_e) => { analyticsClickEvent('partner_quote_calculator', '') }}
                        >
                             <span className=''>
                                Get A Quote                                
                            </span>
                            <img src="images/right-arrow-shop.svg" width={25} alt="icon" />
                        </button>
                    </Link>
                </div>
                </div>
            </div>
        </div>) 
        } 
        </div>
        <div className="modal model-error fade" id="thirdPartyWebsiteModel"  role="dialog" data-keyboard="false" aria-labelledby="thirdPartyWebsitePopupLabel" tabIndex="-1">
          <div class="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div class="modal-body">
                <div className="logo">
                  <img src="images/modal-logo.svg" class="img-fluid" alt="logo"/>
                </div>

                <p className="text-center mt-4 mb-0 ">{thirdPartyWebsiteAlertPopupText}</p>
              </div>

              <div class="modal-footer mb-4">
                <button type="button" className="btn btn-outline btn-warning" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => {
                    this.redirectionThirdPartnerSite().catch(error => console.error(error)) }} >Ok</button>
              </div>


            </div>
          </div>
        </div>
        </>
        
        );
    }
}
 
export default PartnerList;