import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const NotificationContent = ({ message, notificationID, handleLinkClick }) => {
    const replaceAnchorTags = (domNode) => {
        if (domNode.name === 'a') {
          const url = domNode.attribs.href;
          const styleString = domNode.attribs.style || '';
          const colorMatch = styleString.match(/color:\s*([^;]+);?/);
          const color = colorMatch ? colorMatch[1] : '';
          return (
            <a
              href={url}
              style={{ color: color }}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handleLinkClick(e, url, notificationID)}
            >
              {domNode.children[0].data}
            </a>
          );
        }
      };
    
    return (
        <span>
          {parse(message, {
            replace: replaceAnchorTags,
          })}
        </span>
    );
};

NotificationContent.propTypes = {
    message: PropTypes.string,
    notificationID: PropTypes.string,
    handleLinkClick: PropTypes.func,
};

export default NotificationContent;