import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../loader/loader';
import { QuoteCalculatorAPI } from '../../action/quoteCalculator/quoteCalculator'
import { QuoteTool } from '../../action/quoteCalculator/quoteTool';
import {NumericFormat} from "react-number-format";
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMUserIdAndLoginState } from '../../utils/GtmDatalayer';
import { footerAddress } from '../../utils/const';
import { connect } from 'react-redux';

class QuoteCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            isRedirect: false,
            customPath: "/quote-calculator-results",
            storeName: '',
            balance: '',
            searchResult: [],
            repaymentPlan: '',
            hasPartnerDetails:{},
            amount: '',
            hasDisplay: true,
            maximum: '',
            errors: {
                AmountError: ''
            },
            storeError: '',
            isErrorModal: false,
            NoRetailerMessage: '',
            isSelected: false,
            displayData: "",
            result: "",
            headerStoreId: '',
            isWebview: '',
            prevRoute: '',
            responseStore: {}


        }
        this.onHandleChange = this.onHandleChange.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.filterResult = this.filterResult.bind(this);
        this._onValueChange = this._onValueChange.bind(this);
    }


    async componentDidMount() {
        const clearStoreNameForRoutes = ['/viewed-partner', '/favorite-partner', '/application','/wallet', '/shop', '/home', '/my-account'];

        window.dataLayer = window.dataLayer || {};
        GTMUserIdAndLoginState();

        if(localStorage.getItem('fromWhere') === 'apply_now' && localStorage.getItem('sessionId') !== null) {
            localStorage.removeItem('sessionId')
            localStorage.removeItem('token');
            localStorage.removeItem('email')
            localStorage.removeItem('UserName');
            localStorage.removeItem('csrfToken');
            localStorage.removeItem('userID');
        }

        const result = this.props.location.state;       
        this.getStoreId(this.props.location.search)
        if (result != undefined) {
            await this.setState({
                searchValue: result.store_Name,
                hasPartnerDetails: {
                    id: result.id,
                    store_name: result.store_Name
                }
            })
        }
        
        const obj = {
            "email": localStorage.getItem('email')
        }
        const response = await QuoteCalculatorAPI(obj);
        this.setState({responseStore: response.data.data.store}, () => {
            this.setStoreId();
            if(this.props.resultStoreName !== null && this.props.resultStoreName !== '' && this.props.resultStoreName !== undefined){
            
                this.validateToShowStoreName(clearStoreNameForRoutes)    
            }
            
            if(this.state.headerStoreId !==null && this.state.headerStoreId !== undefined){
                this.setPartnerName();
            }
        });
        
        if (response) {
            if (response.data.statusCode === 200) {

                this.setState({ storeName: response.data.data.store, repaymentPlan: response.data.data.repayment_plan, balance: response.data.data.card_balance })

            }
        }
        
    }

    validateToShowStoreName = (clearStoreNameForRoutes) => {
        if(clearStoreNameForRoutes.includes(localStorage.getItem('prevRoute'))){
            this.props.unsetStoreName();
        }
        else {
            this.setState({searchValue: this.props.resultStoreName}) 
        }
    }


    /* Get StoreId from current Route so amount input field will be displayed based on
     flag headerStoreId whether empty or not
    */
    getStoreId = (headerData) => {
        this.setState({prevRoute: headerData});
        // Get the search route values using URLSearchParams() method
        let searchParams = new URLSearchParams(headerData);
        let resultWebview = searchParams.get("isWebview");        
        let resultRetailerId = searchParams.get("store");
        /* splitValue.substring(splitValue.indexOf("%2") + 1, splitValue.lastIndexOf("%22")) */
        
        this.setState({headerStoreId: resultRetailerId});
        this.setState({isWebview: resultWebview})   
        
    
    }

    async onHandleChange(e) {
        let Value = e.target.value

        if (Value.trim() === '') {
            this.setState({
                storeError: "Retailer is required",
                isSelected: false,
                hasPartnerDetails:{}
            })

            console.log("Requried", this.state.storeError)
        }

        else if (this.state.searchResult.length == 0) {
            this.setState({ storeError: "Retailer is not in the list", hasDisplay: false, hasPartnerDetails:{} })
        }
        else {
            console.log(this.state.hasDisplay)
            this.setState({
                storeError: "", hasDisplay: true
            })
        }
        console.log("value", Value)
        await this.setState({ searchValue: e.target.value })
        this.filterResult();
    }

    filterResult() {
        let filterDetails;
        filterDetails = this.state.storeName.filter(
            (listitem) => {

                return listitem.store_name.toLowerCase().includes(this.state.searchValue?.toLocaleLowerCase());
            }
        );
        this.setState({ searchResult: filterDetails });
        // console.log(this.state.searchResult)
    }
    /* set the retailerIds in the list of states to display in the UI while the user can 
    searching the retailer Id during typing the retailer id in the store input field */    
    suggestionSelected = (values) => {        
        this.setState({ searchValue: values.store_name, hasPartnerDetails: values, hasDisplay: false })
        this.setState({ isSelected: true })
        this.setState({ storeError: "" })
        console.log("Search Result", values)
    };

    onKeyPressBlock = (event) => {
        if(event.key === '-'){
            event.preventDefault();
            return false;
        }
    }

    _isAllowedCheck = (values) =>{
        if(values != ''){
            const { value } = values;
            return Boolean(value <= 1000000);
        }
    }
 
    async _onValueChange(event) {
        let { formattedValue, value } = event;
        if (Number(value) <= 1000000) {

            if (parseFloat(value) != 0) {
                await this.setState({ amount: value, ErrorMessage: "", displayData: formattedValue });
            }
            else {
                this.setState({ displayData: "" })
            }
        }
        else {
            console.log("false")
        }
                  
        if (value.length == 0 && value == "") {
            this.setState({ errors: { AmountError: "Quote Amount is required" } });
        }
        else {
            this.setState({ errors: { AmountError: "" } })
        }

    }

    async handleRedirect() {
        analyticsClickEvent('quote_landing_page_submit','')
        this.setState({ loading: true })
        let obj;
        if(this.state.hasPartnerDetails !== '' && this.state.hasPartnerDetails !== undefined) {
            obj = {
                "store_id": this.state.hasPartnerDetails.id,
                "amount": this.state.amount
            }
        }
        else {
            obj = {
                "store_id": this.state.headerStoreId,
                "amount": this.state.amount
            }
        }         
        console.log("Result Object", obj)
        const result = await QuoteTool(obj);
        if (result.data.statusCode == 400) {
            this.setState({
                loading: false,
                NoRetailerMessage: result.data.message
            })
            window.$("#commonModelRetail").modal("show")            
        }
        else if(result.status === 200) {
            this.setState({ result: result.data }, () => {
                this.redirectToQuoteCalcualulatorResults()
            })

        }


    }

    setStoreId() {

        let updateState = {};
        const { responseStore, searchValue } = this.state;
        const result = responseStore.find(value => value.store_name === searchValue);
        if (result) {
            updateState = {
                hasPartnerDetails: {
                    id: result.id,
                    store_name: result.store_name
                }
            };
            console.log(result.id);
        }
        this.setState(updateState);
        
    }
    

    setPartnerName() {
        
        if(this.state.hasPartnerDetails) {
            const value = this.state.responseStore.find(item => item.id === this.state.headerStoreId);
            if(value) {
              this.setState({
                hasPartnerDetails: {
                  id: value.id,
                  store_name: value.store_name
                },
                searchValue: value.store_name
              });
            }
          }

        
        
    }

    addClassnames = (displayState) => {
        if(displayState){
            return "search-body show-option"
        }
        else {
            return "search-body hide-option"
        }
    }
    

    onBlurChange = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        
        if (name === 'purchase-amount') {
            if (value.length !== 0) {
              if(value.indexOf(".") >= 0){
                let decimal_pos = value.indexOf(".");
                let left_side = value.substring(0, decimal_pos);
                let right_side = value.substring(decimal_pos);
                let replaceLeftSide=left_side.replace(/\D/g, "").split(',').join('');
                right_side = right_side.substring(0, 3);
                if(right_side.length===2){
                  right_side=right_side+'0';
                }
      
              if(right_side.length===1){
                  right_side='.00';
              }
              value=replaceLeftSide+right_side;
              this.setState({displayData: value})
              
              }else{
                value = value.replace(/\D/g, "").split(',').join('')+'.00';
                this.setState({ displayData:value })
              
              }
      
              
            }
          }
    }

    clickEventForShopBackButton = () => {
        this.props.history.replace(this.props.history.goBack({pathname: localStorage.getItem('prevRoute') }));

        analyticsClickEvent('shop_back','')
    }
    
    redirectToQuoteCalcualulatorResults = () => {
        const { repaymentPlan, balance, customPath, hasPartnerDetails, amount, prevRoute, result } = this.state;
        
        this.props.history.push({
            pathname: customPath,
            search: localStorage.getItem('webView') ? '?isWebview=true' : '',
            state: { planDetails: repaymentPlan, balanceDetails: balance, partner: hasPartnerDetails, requestAmount: amount, result: result, routeHeader: prevRoute }
        })
    }

    render() {

        return (
            <>
            <div className="wrapper">
                <Loader loaderStatus={this.state.loading} />


                {/* search nav */}
                <section className={localStorage.getItem('webView') ? `main mt-2` : `main`}>
                    { localStorage.getItem('webView') ? '' :
                    <div className="search-fav-section">
                        <div className="container">
                            <div className="d-flex align-items-center top-section-partner">
                                <div className="left-iteQerd">
                                    <div className="back-btn-item">
                                        <a className="back-btnQr"
                                            onClick={this.clickEventForShopBackButton}
                                        ><img src="images/back.svg" alt="<-" /> Back </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    }
                    <center className='quote-tool-landing'>
                        <div className='top-nav-bar--quote-tool mt-4 mb-4 eligible-crit'>
                            <div className='my-2 quote-logo'>
                                <img src="images/loading-logo-img.png" alt="header icon" width="75" />
                            </div>
                            <h2 className='q-landing-page--title'>Get a quote</h2>
                            <h5 className='text-left'><span style={{fontWeight: "600",display: "inline",color: "#212529",fontStyle: "italic"}}>
                                humm</span> is committed to providing you with financial solutions that are tailored to your needs.
                            </h5>
                            <h5 className='text-left'>
                            We do have some basic criteria you'll have to meet before we can consider your application. You must:
                            </h5>
                            <ul>
                                <li>Be aged 18 to 75</li>
                                <li>Have a regular income of at least £1,000 per month</li>
                                <li>Have been a resident of the UK for at least 6 months</li>
                                <li>Have a UK credit or debit card in your own name</li>                            
                            </ul>
                        </div>

                        <div className="Q-header-center Quote-header">                            
                            
                            <p className='h5 font-weight-normal'>
                                <span className='d-block mt-1 mb-0'>This criteria allows us to ensure that <caption style={{fontWeight: "600",display: "inline",color: "#212529",fontStyle: "italic"}}>
                                humm</caption> is right for you.</span>

                                <span className='d-block mt-2'>To get started with <caption style={{fontWeight: "600",display: "inline",color: "#212529",fontStyle: "italic"}}>
                                humm</caption>, you can use our Quote Calculator to get an example of your payment plan. 
                                    <span className='font-italic mx-0'> Please note that the calculator is for illustrative purposes only and may not reflect the exact terms of your loan. </span>
                                </span>

                                <span className='d-block mt-2'>If approved for a <caption style={{fontWeight: "600",display: "inline",color: "#212529",fontStyle: "italic"}}>
                                humm</caption> loan, you will be provided with a clear and transparent payment plan, so you can budget accordingly and avoid any surprises. </span>                          
                            </p>
                            
                        </div>
                    </center>
                </section>
                <section className="section py-1">
                    <center>
                        <div className="container--quote-tool">
                            <form>
                                <div className="Qc-form-body-section text-left">
                                    <div className="row">
                                    
                                        <div className="col-md-6 col-sm-12 col-12 my-0" style={{ position: 'relative' }}>
                                            
                                            <div className="form-group store-name">
                                                <label>Retailer</label>
                                                
                                                
                                                    <input type="text" className="form-control store-name" name="store"
                                                        value={this.state.searchValue}
                                                        placeholder="Start typing"
                                                        autoComplete="off"
                                                        onChange={(e) => this.onHandleChange(e)} />
                                                
                                            </div>
                                        
                                            {/* Display the retailers list by getting the list from the function() suggestionSelected  */}


                                            <div className="search-result-item">
                                                <div className={ this.addClassnames(this.state.hasDisplay) } >
                                                    <ul className="m-0 p-0">
                                                        {this.state.searchValue &&
                                                            this.state.searchResult.map(list =>
                                                                <li className="search-list" key={list.id}
                                                                    onClick={(_e) => this.suggestionSelected(list)}>{list.store_name}</li>
                                                            )

                                                        }


                                                    </ul>
                                                </div>
                                                <p className="error-message card-holder-error">{this.state.storeError}</p>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="form-group">
                                                <label>Quote Amount</label>
                                                <NumericFormat 
                                                    className={"form-control amount-input"}
                                                    placeholder="Enter amount"                                                
                                                    name="purchase-amount"
                                                    value={this.state.displayData}
                                                    decimalScale={2}
                                                    isAllowed={this._isAllowedCheck}
                                                    thousandSeparator={true}
                                                    prefix={"£"}
                                                    autoComplete="off"
                                                    onValueChange={this._onValueChange}
                                                    onKeyPress={this.onKeyPressBlock}
                                                    onBlur={this.onBlurChange}
                                                />
                                                <p className="error-message card-holder-error">{this.state.errors.AmountError}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                

                                <div className="qc-footer-section">                                
                                    {
                                            <button disabled={this.state.errors.AmountError != "" || this.state.storeError != "" || this.state.displayData === ""  || this.state.searchResult.length == 0 && Object.values(this.state.hasPartnerDetails).length == 0 || Object.values(this.state.hasPartnerDetails).length == 0} type="button" className="qc-confirm-btn btn" onClick={this.handleRedirect}>Confirm</button>

                                    }
                                    
                                </div>
                            </form>

                        </div>
                    </center>

                    <div className='Quote-footer mx-5 mt-4 mb-2'>
                        <p className='mx-auto mb-0'>
                        <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                        humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                        humm</span> offers both regulated and unregulated products. 
                        Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. Registered office: {footerAddress}
                        Company Number: NI675430. Data Protection Register Number: ZB029507
                        </p>
                    </div>
                </section>

                <div
                    className="modal fade model-error"
                    id="commonModelRetail"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="commonModellLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header"></div>
                            <div className="modal-body">
                                <div className="logo">
                                    <img
                                        src="images/modal-logo.svg"
                                        alt="logo"
                                        className="img-fluid"
                                    />
                                </div>
                                <h5 className="modal-title " id="commonModellLabel">
                                No Plans Found
                                </h5>
                                <p className="text-center mt-4 mb-4">
                                    {this.state.NoRetailerMessage}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            );
    }
}

export const mapStateToProps = (state) => {
    return {
        resultStoreName: state.dataStore.resultStoreName
    };
   };
  
  export const mapDispatchToProps = (dispatch) => {
    return {
        setStoreName:(data)=>{
            return dispatch({
                type: 'SET_STORE_NAME',
                value: data
            });
         },
        unsetStoreName:(data)=>{
            return dispatch({
                type: 'UNSET_STORE_NAME',
                value: data
            });
        },
    }
   }


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(QuoteCalculator));

