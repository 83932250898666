import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useMemo,useState,useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  ConfirmPaymentIntent,
  CreatePaymentIntent,
  CreateSetupIntent,
  CreateStipeCustomer,
  GetStipeCustomerData,
  AttachPaymentMethod
} from "../../action/card/paymentIntent";
import useResponsiveFontSize from "./useResponsiveFontSize";
import $ from "jquery";
import { analyticsClickEvent, analyticsTypeEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";



const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  return useMemo(
    () => ({
      placeholder: 'Card Number',
      style: {
        base: {
          fontSize,
          color: "#4a4a4a",
          letterSpacing: "-0.4px",
          fontFamily: 'ProximaNova-Regular',
          fontWeight:'400',
          "::placeholder": {
            color: "#6c757d",
            fontSize:'18px',
            fontWeight:'500'
          },
          Height:"25px"          
          
        },
        invalid: {
          color: "#4a4a4a",
          placeholder: 'Card NO'
        },
      },
      
    }),
    [fontSize]
  );
};

const CardExpirOptions = () => {
  const fontSize = useResponsiveFontSize();
  
  return useMemo(
    () => ({
      placeholder: 'MM/YY',
      style: {
        base: {
          fontSize,
          color: "#4a4a4a",
          letterSpacing: "-0.4px",
          fontFamily: "ProximaNova-Regular",
          "::placeholder": {
            color: "#6c757d",
            fontSize:'18px',
            fontWeight:'500'
          }, 
          Height:"25px"
          
        },
        invalid: {
          color: "#4a4a4a",
          placeholder: 'Card NO'
        },
      },
      
    }),
    [fontSize]
  );
};

const CvvOptions = () => {
  const fontSize = useResponsiveFontSize();
  return useMemo(
    () => ({
      placeholder: 'CVV',
      style: {
        base: {
          fontSize,
          color: "#4a4a4a",
          letterSpacing: "-0.4px",
          fontFamily: "ProximaNova-Regular",
          "::placeholder": {
            color: "#6c757d",
            placeholder:"12345678",
            fontSize:'18px',
            fontWeight:'500'
          },           
          Height:"25px"
          
        },
        invalid: {
          color: "#4a4a4a",
          placeholder: 'Card NO'
        },
      },
      
    }),
    [fontSize]
  );
};

  // Click Events
  const clickEventForCardNumber = ()=>{
    return analyticsClickEvent('credit_card_number','');
  }

  const clickEventForCardMonthAndYr = () => {
    return analyticsClickEvent('credit_card_month_and_year','')
  }

  const clickEventForCardCvv = () => {
    return analyticsClickEvent('credit_card_cvv','')
  }

const PaymentForm = (props) => {
  console.log('paymentform');

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const cvvOpt = CvvOptions();
  const cardExpOpt= CardExpirOptions();
  const eventCardNumber = clickEventForCardNumber();
  const eventCardMonthAndYr = clickEventForCardMonthAndYr();
  const eventCardCvv = clickEventForCardCvv();
  

  const [cardNoError, setcardNoError] = useState('');
  const [cardExpError, setcardExpError] = useState('');
  const [cardCvcError, setcardCvcError] = useState('');
  const [cardError, setCardError] = useState('');


  if(elements != null && elements.getElement(CardNumberElement) != null && $('#addCard').hasClass('show') === false){
    console.log(elements.getElement(CardNumberElement));
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
  }

  const onReadyCardNumberElement = () => {
    elements.getElement(CardNumberElement).clear();
    
    setcardNoError('');
  }
 
  const handleSubmit = async (event) => {
     document.getElementById("confirm-submit").disabled = true;
     analyticsClickEvent('add_card_popup_confirm','')
   console.log('handle submit',props.isCheckcardHolderValid);
   console.log(props);
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      document.getElementById("confirm-submit").disabled=false
      return;
    }

   
 

    // create strip customer
    // let userData = {
    //   name: localStorage.getItem("UserName"),
    //   email: localStorage.getItem("email"),
    // };
   
    // const customerRes = await CreateStipeCustomer(userData);
    // console.log("customerCreat", customerRes);
   
    //   create stepintent
    let userEmail = {
      email: localStorage.getItem("email"),
    };
    const res = await CreateSetupIntent(userEmail);
    console.log("create setup intent", res);
    console.log("create setup intent secret key", res.data.data.client_secret);
    let secret = res.data.data.client_secret;

    //   // confirm card setup

    let confirmcardsetup = await stripe.confirmCardSetup(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          // name: localStorage.getItem("UserName"),
          name: props.cardHolderName,
        },
      },
    });
    console.log("stripe confirm setup", confirmcardsetup);

   props.cardHolderCheckValidation();
   console.log('props',props);
   if(props.isCheckcardHolderValid != ''){
    document.getElementById("confirm-submit").disabled=false;
     return false;
   }
    // if(confirmcardsetup.error){
      if(confirmcardsetup.error  && cardNoError == '' && cardExpError == '' && cardCvcError == ''){
      setCardError(confirmcardsetup.error.message);
      console.log('error',confirmcardsetup.error.messsage);

      document.getElementById("confirm-submit").disabled=false; 
      // console.log('car5dholder name vali',props.cardHolderCheckValidation());
      return false;
    }
   
    if(confirmcardsetup.error != undefined){
      setCardError(confirmcardsetup.error.message);
      document.getElementById("confirm-submit").disabled=false;
      return false;
    }
    let attachPaymentMenthod = {
        paymentMethodId: confirmcardsetup.setupIntent.payment_method,
        email: localStorage.getItem('email')
    }

    const response = await AttachPaymentMethod(attachPaymentMenthod);
    
    document.getElementById("confirm-submit").disabled=false;
    if(response.status===200){
      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear(); 
      localStorage.setItem('cardMessage', response.data.message);
      props.callCardProcessSucess(response.status);
    }

    // create payment intent
    // let payData = {
    //     amount: 2000,
    //     paymentMethodId: confirmcardsetup.setupIntent.payment_method,
    //     customerId: customerRes.data.data.id
    // }
    // const response = await CreatePaymentIntent(payData);
    // console.log('paymentintent', response);

    // // strip confirmpayment data
    // let confirmData = {
    //     "paymentIntentId": response.data.data.id
    // }
    // const confirmDataRes = await ConfirmPaymentIntent(confirmData);
    // console.log('confirmdatares', confirmDataRes);
    //   // create payment method
    //   const paymentMethod  = await stripe.createPaymentMethod({
    //     type: "card",
    //     card: elements.getElement(CardNumberElement)
    // });
  };

  function onChangeCardNumberElement(event){
    console.log(event);
    
    if(event.error!==undefined){
      setcardNoError(event.error.message);
    }else{
      setcardNoError('');
      setCardError('');
    }
  }

  function onReadyCardExpiryElement(){
    setcardExpError('');
  }

  function onChangeCardExpiryElement(event){
    // analyticsTypeEvent('credit_card_month_and year') 
    console.log("CardNumberElement [change]", event);
    if(event.error!==undefined){
      setcardExpError(event.error.message);
    }else{
      setcardExpError('');
      
    }
  }

  function onReadyCardCvcElement(){
    setcardCvcError('');
  }

  function onChangeCardCvcElement(event){
    // analyticsTypeEvent('credit_card_cvv') 
    console.log("CardNumberElement [change]", event);
              
    if(event.error!==undefined){
      setcardCvcError(event.error.message);
    }else{
      setcardCvcError('');
      
    }
  }

  console.log(stripe)
  return (
    
    <form onSubmit={handleSubmit}>
      <label>
        {/* Card number */}
        <div class="StripeElement card_number proxima-nova-bold">
          <CardNumberElement
            options={options}
            onReady={onReadyCardNumberElement}
            onChange={onChangeCardNumberElement}
            onClick = {eventCardNumber}            
          />
          <p class="error-message card-holder-error">
            <span>{cardNoError}</span>
          </p>
        </div>
      </label>
      <label className="mb-3">
        {/* Expiration date */}
        <div class="StripeElement exp_date custom-strip-field">
          <CardExpiryElement
            className="StripeExpiery"
            options={cardExpOpt}
            onReady={onReadyCardExpiryElement}
            onChange={onChangeCardExpiryElement}
          
            onClick = {eventCardMonthAndYr}
          />
          <p class="error-message card-holder-error">
            <span>{cardExpError}</span>
          </p>
        </div>
        <div class="StripeElement cvc_number">
          <CardCvcElement
            options={cvvOpt}
            onReady={onReadyCardCvcElement}
            onChange={onChangeCardCvcElement}
            onClick = {eventCardCvv}
          />
          <p class="error-message card-holder-error">
            <span>{cardCvcError}</span>
          </p>
        </div>
      </label>
      {/* <label>
        { CVC }
        
      </label> */}
      {cardNoError=='' && cardExpError=='' && cardCvcError=='' &&
       <p class="error-message card-error">
            <span>{cardError}</span>
          </p>
          }
      <br />
      <p className={cardExpError !=='' || cardCvcError !== '' ?'add-card--note-max-margin-top text-left': 'add-card--note-min-margin-top text-left'}>
        <span className="font-weight-bold">Please note: </span> Making payments by credit card may result in getting into more debt, so please carefully consider your card choice. <a href="https://www.shophumm.com/uk/faqs/what-should-i-consider-before-using-a-credit-card-against-my-loan-agreement/" target='_blank' 
        rel="noopener noreferrer" className="add-card--click-here-link" >Click here</a> for more info.
      </p>
     
      <button type="submit" className="btn btn-primary mt-3" id="confirm-submit" disabled={!stripe}>
        Confirm
      </button>
     
    </form>
  );
};

export default withRouter(PaymentForm);
