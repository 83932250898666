import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../header/header';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';
import { frontEndLogs } from '../../action/logs/frontEndLogs';

// Convert base64 key to binary format (decoded)
const privateKey = CryptoJS.enc.Base64.parse(process.env.REACT_APP_BASE64PRIVATE_KEY);//Decrypt Key
export class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            location: useLocation
        }

        this.initialLoad = this.initialLoad.bind(this);
    }
    initialLoad = () => {        
        console.log("current header", window.location.search);
        if(window.location.pathname === '/application' && localStorage.getItem('sessionId') == null){
            localStorage.setItem('fromWhere','apply_now');
        }
        // Get the search route values using URLSearchParams() method
        let searchParams = new URLSearchParams(window.location.search);
        let resultWebview = searchParams.get("isWebview");
        let resultMobileApps = searchParams.get("device");  
        if(resultWebview !== null && resultWebview !== undefined && resultWebview !== '' && resultWebview === 'true'){
            localStorage.setItem('webView', true);
        }
        if(resultMobileApps !== null && resultMobileApps !== undefined && resultMobileApps !== '' && resultMobileApps === 'mobile'){
            localStorage.setItem('mobile', true);
        }

        this.assignDataValuesForPurchaseJourney();
        let redirectPathname = searchParams.get("URLPath");
        if(redirectPathname != null && redirectPathname !== '' && redirectPathname != undefined){
        this.props.setCustomPath(redirectPathname);
        }
    }

    assignLoanAmountIfExists = (loanAmount,trackingId) => {
        const cookie = new Cookies();

        if(loanAmount !== null && loanAmount !== undefined && loanAmount !== '' && trackingId){
            cookie.set('loanAmount',loanAmount,{ path: '/', maxAge: 3600 });
        }
    }

    assignApprovedURLIfExists = (params) => {
        const cookie = new Cookies();    
        let approvedURL = params.get('approvedURL');
        let source = params.get('source');
        let retailerReference = params.get('ref');
        if(approvedURL !== null && approvedURL !== undefined && approvedURL !== '' && approvedURL.includes('trackingId')){
            if(source !== '' && source !== undefined && source !== null) {
                approvedURL += '&source=' +source;
            }
            cookie.set('approvedURL',approvedURL ,{ path: '/', maxAge: 3600 });
        }

        if(retailerReference) {
            cookie.set('retailerReference',retailerReference ,{ path: '/', maxAge: 3600 });
        }
    }

    decryptAES = (payload, key) => {
    const encryptedData = CryptoJS.enc.Base64.parse(payload);
    const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4));
    const ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4));

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
    }

    assignParamValues = (params) => {
        const cookie = new Cookies();
        let term = params.get("term");
        let firstName = params.get('firstName');
        let lastName = params.get('lastName');
        let email = params.get("email");
        let mobileNumber = params.get("mobileNum");

        if(term !== null && term !== undefined && term !== ''){
            cookie.set('term',term,{ path: '/', maxAge: 3600 });
        }

        if(firstName !== null && firstName !== undefined && firstName !== ''){
            cookie.set('firstName',firstName,{ path: '/', maxAge: 3600 });
        }

        if(lastName !== null && lastName !== undefined && lastName !== ''){
            cookie.set('lastName',lastName,{ path: '/', maxAge: 3600 });
        }

        if(email !== null && email !== undefined && email !== ''){
            cookie.set('email',email,{ path: '/', maxAge: 3600 });
        }

        if(mobileNumber !== null && mobileNumber !== undefined && mobileNumber !== ''){
            cookie.set('mobileNumber',mobileNumber,{ path: '/', maxAge: 3600 });
        }

    }

    assignDataValuesForPurchaseJourney = () => {
        let searchParams = new URLSearchParams(window.location.search);
    const cookie = new Cookies();
        let redirectToken = searchParams.get('redirectToken'); 

        if (process.env.REACT_APP_ENCRYPTED_PURCHASE_URL === 'true' && redirectToken) {

        searchParams = window.location.search;
        let encrytedUrl = searchParams.match(/redirectToken=([^&]*)/);
        encrytedUrl = encrytedUrl ? decodeURIComponent(encrytedUrl[1]) : null;
        
        const decryptedPayload = this.decryptAES(encrytedUrl, privateKey);
        cookie.set('decryptedPayload',decryptedPayload,{ path: '/', maxAge: 3600 });
        frontEndLogs('Decrypted redirect Token in the purchase link',false,200,'assignDataValuesForPurchaseJourney',decryptedPayload);
        const params = new URLSearchParams(decryptedPayload);    
        this.handleSetDecryptionDetails(decryptedPayload,params);
        this.assignParamValues(params);        
        this.purchaseParamsStoredInCookies(params);
        } else {
        let loanAmountData = searchParams.get("loanAmt");            
        if(window.location.href.includes('trackingId'))
        {
            frontEndLogs('Purchase link params ',false,200,'assignDataValuesForPurchaseJourney',searchParams);
            let sourceURL = window.location.href;
            if(sourceURL !== null && sourceURL !== undefined && sourceURL !== ''){
                cookie.set('sourceURL',sourceURL,{ path: '/', maxAge: 3600 });
            }
        }

        let trackingId = window.location.href.includes('trackingId')
        
        this.assignLoanAmountIfExists(loanAmountData,trackingId);
        this.assignApprovedURLIfExists(searchParams);
        this.assignParamValues(searchParams);
        this.purchaseParamsStoredInCookies(searchParams);
        }
    }

    handleSetDecryptionDetails = (decryptedPayload,params) => {
        const cookie = new Cookies();
        let loanAmountData = params.get("loanAmt");
        if (decryptedPayload.includes('trackingId')) {
            frontEndLogs('Purchase link params ', false, 200, 'assignDataValuesForPurchaseJourney', params);
            let sourceURL = window.location.href;
            if (sourceURL !== null && sourceURL !== undefined && sourceURL !== '') {
                let decryptedPayload = cookie.get('decryptedPayload');
                let resultSourceURL = `${window.location.origin}${window.location.pathname}?${decryptedPayload}`

                cookie.set('sourceURL', resultSourceURL, { path: '/', maxAge: 3600 });
            }
        }

        let approvedUrl = params.get("approvedURL");
        const decodedApprovedUrl = decodeURIComponent(approvedUrl);
        const approvedParams = new URLSearchParams(decodedApprovedUrl.split('?')[1]);
        let trackingId = decryptedPayload.includes('trackingId');

        this.assignLoanAmountIfExists(loanAmountData, trackingId);
        this.assignApprovedURLIfExists(params);
        let purchaseLinkTrackingId = approvedParams.get("trackingId");
        let branchReferrer = params.get("_branch_referrer");

        if (branchReferrer !== null && branchReferrer !== undefined && branchReferrer !== '') {
            cookie.set('branchReferrer', branchReferrer, { path: '/', maxAge: 3600 });
        }

        if (purchaseLinkTrackingId !== null && purchaseLinkTrackingId !== undefined && purchaseLinkTrackingId !== '') {
            cookie.set('trackingId', purchaseLinkTrackingId, { path: '/', maxAge: 3600 });
        }
    }

    purchaseParamsStoredInCookies = (searchParams)=>{
        const cookie = new Cookies();
        let Dob = searchParams.get("dob");
        let flatAbode = searchParams.get("flatAbode");
        let houseandBuildingName = searchParams.get("houseOrBuildingName");
        let houseandBuildingNumber = searchParams.get("houseOrBuildingNum");
        let numberAndStreet = searchParams.get("mailingStreet");
        let addressLine2 = searchParams.get("mailingStreetLineTwo");
        let townOrCity = searchParams.get("mailingCity");
        let postcode = searchParams.get("mailingPostalCode");
        if(Dob !== null && Dob !== undefined && Dob !== ''){
            cookie.set('Dob',Dob,{ path: '/', maxAge: 3600 });
        }

        if(numberAndStreet !== null && numberAndStreet !== undefined && numberAndStreet !== ''){
            cookie.set('numberAndStreet',numberAndStreet,{ path: '/', maxAge: 3600 });
        }

        if(addressLine2 !== null && addressLine2 !== undefined && addressLine2 !== ''){
            cookie.set('addressLine2',addressLine2,{ path: '/', maxAge: 3600 });
        }

        if(townOrCity !== null && townOrCity !== undefined && townOrCity !== ''){
            cookie.set('townOrCity',townOrCity,{ path: '/', maxAge: 3600 });
        }

        if(postcode !== null && postcode !== undefined && postcode !== ''){
            cookie.set('postcode',postcode,{ path: '/', maxAge: 3600 });
        }

        if(flatAbode){
            cookie.set('flatAbode',flatAbode,{ path: '/', maxAge: 3600 });
        }

        if(houseandBuildingName){
            cookie.set('houseandBuildingName',houseandBuildingName,{ path: '/', maxAge: 3600 });
        }

        if(houseandBuildingNumber){
            cookie.set('houseandBuildingNumber',houseandBuildingNumber,{ path: '/', maxAge: 3600 });
        }

    }

    acceptMethods(_childDoAlert) {
        // Parent stores the method that the child passed
      }

    render() { 
        this.initialLoad();
        return ( 
            <React.Fragment> 

            {localStorage.getItem('webView')!=null && localStorage.getItem('mobileRequired') === 'true' && 
            <></>  }

            {localStorage.getItem('webView') == null && localStorage.getItem('mobileRequired') == null && 
            <>
            <Header  shareMethods={this.acceptMethods()}/>
            </>  }

            {localStorage.getItem('mobileRequired') === "false" &&
            <>
            <Header  shareMethods={this.acceptMethods()}/>
            </>            
            }

            {this.props.children}
            </React.Fragment>

         );
    }
}

export const mapStateToProps = (state) => {
    return {
        customPathname: state.dataStore.customPathname
    };
   };
  
  export const mapDispatchToProps = (dispatch) => {
    return {
        setCustomPath:(data)=>{
         return dispatch({
             type: 'SET_CUSTOM_PATH',
             value: data
         });
     }
    }
   }
 
   export default connect(mapStateToProps,mapDispatchToProps)(Layout);