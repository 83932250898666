import React, { Component } from "react";

export default class DeCommissioning extends Component {
    constructor(props) {
        console.log('child',props);
        super(props);
        this.state = {
            itemValue: '',
        }
    }

  

    render(props) {        
        return (
            <div className="hero-section col-lg-12 pb-3">
                    <div class="decommissioning">
                
                <div class="card-details tittle-active">
                <p>humm's mobile app will be discontinued on <b>{process.env.REACT_APP_DECOMMISSIONING_DATE ? process.env.REACT_APP_DECOMMISSIONING_DATE : process.env.REACT_APP_DECOMMISSIONING_DAYS}</b>, but don't worry! Access all humm features seamlessly on any web browser, mobile, or desktop. For more information, visit our <a href="https://www.shophumm.com/uk/help-centre/" target="_blank">FAQs</a>.</p>
                </div>
                </div>
            </div>
        );
    }
}