import React from "react";
import { connect } from 'react-redux';
import SimpleSlider from './simpleSlider';
import CategorySlider from './categorieSlider';
import { getPartnerSearch } from '../../../action/partner/getPartnerSearch';
import HomeBanner from "./homeBannerOne";
import HomeBannerInactive from "./HomeBannerInactive";
import Loader from "../../loader/loader";
import { Link, withRouter } from 'react-router-dom';
import { getContractSummary } from '../../../action/contract/getContractSummary';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import {getApplicationStatus} from "../../../action/application/getApplicationStatus"
import { GTMUserIdAndLoginState } from "../../../utils/GtmDatalayer";
import DeCommissioning from "./deCommissioning";

export class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            messageVisibility: true,
            profilePicture: props.profilePicture,
            FeaturedPartnerSliderData: '',
            AllPartnerSliderData: '',
            displayClass: 'none',
            setUniqueIndustries: '',
            sticky: 'fadeInDown animated fadeOutUp',
            isLoading: false,
            renderChild: '',
            contractSummary: props.contractSummary,
            applicationImage: 'images/home-active-contract-bg.png',
            applicationStatus:''


        };
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.profilePicture !== nextProps.profilePicture) {
            return {
                profilePicture: nextProps.profilePicture
            };
        }
        if (prevState.contractSummary !== nextProps.contractSummary) {
            return {
                contractSummary: nextProps.contractSummary
            };
        }
        return null;
    }


    handleScroll = (e) => {

        if (window.pageYOffset > 249) {
            this.setState({ sticky: 'fadeInDown animated' });
        } else {
            this.setState({ sticky: 'fadeInDown animated fadeOutUp' });
        }

    }

    async componentDidMount() {
        // analyticsScreenViewEvent()
        window.dataLayer = window.dataLayer || {}; 
        GTMUserIdAndLoginState();

        await this.initialLoad('comp');
        window.addEventListener('scroll', this.handleScroll);
        window.onpopstate = () => {
            const { location, history } = this.props;
            if (location.pathname === '/welcome') {
                history.go(1);
            }
        };

        
        let appImg = localStorage.getItem("applicationImage");
        if (appImg === null) {
            let appliImg = this.props.location.state;
            localStorage.setItem('applicationImage', appliImg.applicationImage);
            this.setState({ applicationImage: appliImg.applicationImage });
        } else {
            this.setState({ applicationImage: appImg });
        }

     if (localStorage.getItem('fromWhere') != 'apply_now' && localStorage.getItem('sessionId') !== null) {
        const obj={
            email:localStorage.getItem('email')
        }
        const result=await getApplicationStatus(obj);
        if(result.data.data){
            this.setState({applicationStatus:result.data.data.current_application_status})
        }
     }
    }

    initialLoad = async (favcallData) => {
        let contractData = this.props.contractSummary;
        if (!contractData || contractData !== '') {
            if (favcallData != 'fav') {
                await this.setState({ isLoading: true });
            }
            if (localStorage.getItem('fromWhere') != 'apply_now' && localStorage.getItem('sessionId') !== null) {
                const contractSummaryResult = await getContractSummary({ email: localStorage.getItem('email') });
                let summary = '';
                if (contractSummaryResult !== undefined) {
                    if (contractSummaryResult.status === 200) {
                        for (const [key, value] of Object.entries(contractSummaryResult.data)) {
                            if (key === "data") {
                                summary = value;
                            }
                        }
                        this.props.getContractSummaryDetails(summary);

                    }
                }
            }
        }




        if (favcallData != 'fav') {
            await this.setState({ isLoading: true });
        }
        const partnerSearch = {
            "Industry": "",
            "Location": "",
            "IsOnline": false,
            "Keywords": "",
            "offers": false,
            "email": localStorage.getItem('email')
        }
        let result = await this.props.getPartnerSearch(partnerSearch);
        result = result.data;
        var FeaturedPartnerData = [];
        var AllPartnerData = [];
        var uniqueIndustries = [];
        // result.data.map((response) => {
        //     const obj = {
        //         partnerId: response.hummuk_account_id,
        //         partnerName: response.hummuk_account_Name,
        //         partnerIndustry: response.hummuk_account_Industry,
        //         partnerLogo: response.hummuk_account_flxLogo__c,
        //         partnerCoverImage: response.hummuk_account_Cover_Image__c,
        //         partnerWebSite: response.hummuk_account_Website,
        //         partnerFavId: response.fav_id
        //     }
        //     if (response.hummuk_account_Featured_Partner__c === true) {
        //         FeaturedPartnerData.push(obj)
        //     }

        //     AllPartnerData.push(obj)
        //     uniqueIndustries.push(response.hummuk_account_Industry)

        // });


        await Promise.all(result.data.map( async(response) => {
        // let backGroundImage=await this.getResponsePartnerImage(response.hummuk_account_Cover_Image__c,response.access_token);
       const obj={
        partnerId: response.hummuk_account_id,
        partnerName: response.hummuk_account_Name,
        partnerIndustry: response.hummuk_account_Industry,
        partnerLogo: response.hummuk_account_flxLogo__c,
        partnerCoverImage: response.hummuk_account_Cover_Image__c,
        partnerWebSite: response.hummuk_account_Website,
        partnerFavId: response.fav_id
       }
        if(response.hummuk_account_Featured_Partner__c===true){ 
           FeaturedPartnerData.push(obj)
        }
       
       AllPartnerData.push(obj)
       uniqueIndustries.push(response.hummuk_account_Industry)
    
   }));

        var newArray = [...new Set(uniqueIndustries)];
        newArray.sort((a,b)=>(a > b ? 1 : -1));
        await this.setState({ setUniqueIndustries: newArray });
        this.setState({ AllPartnerSliderData: AllPartnerData });
        this.setState({ FeaturedPartnerSliderData: FeaturedPartnerData });
        this.IndustriesBased();
        this.setState({ isLoading: false });


    }




    toggleMessage = () => {

        this.setState(prevState => ({
            messageVisibility: !prevState.messageVisibility
        }));

        if (this.state.messageVisibility) {
            this.setState({ displayClass: 'block' });
        } else {
            this.setState({ displayClass: 'none' });
        }
    };


    IndustriesBased = () => {
        if (this.state.setUniqueIndustries) {
            return this.state.setUniqueIndustries.map((cat_item) => {
                var item = [];
                return <section className="featured-partners" key={cat_item}>
                    <div className="container">
                        <div className="feature-section">
                            <div className="featured-title-section">
                                {cat_item!=null && cat_item!=undefined && cat_item!='' &&
                                    <h6 className="section-main-title">{cat_item.toUpperCase()}</h6>
                                }   
                                {cat_item !=null && cat_item !=undefined && cat_item !='' &&
                                    <p className="section-sub-title">Just some of our amazing {cat_item} partners</p>
                                }
                            </div>
                            <div className="featured-partners-list">
                                {
                                    this.state.AllPartnerSliderData.map((src_data) => {
                                        if (src_data.partnerIndustry === cat_item) {
                                            item.push(src_data);
                                        }
                                    })
                                }
                                <SimpleSlider items={item} favCall={this.favcall} />
                            </div>
                        </div>
                    </div>
                </section>

            })
        }
    }


    stickyPosition = () => {
        if (localStorage.getItem('fromWhere') === 'apply_now') {
            return {};
        }
        return <div className={`lr-item--hero scroll-animation ${this.state.sticky}`}>
            <div className="hero-left">
                <div className="hero-left-top container custom-container-sec">
                    <div className="col-lg-2 col-md-2 p-0 user-info">
                        <div className="profile-item-sec">
                            <span className="user-image-area sticky-profile-img">
                                {this.state.profilePicture !== '' && this.state.profilePicture !== null &&
                                    <img src={this.state.profilePicture} alt="User avatar" />
                                }
                                {this.state.profilePicture === null &&
                                    <div className="no-profile-image">
                                        {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                                            <span className="no-profile-image-letter-sticky">{localStorage.getItem('UserName').substring(0, 1).toUpperCase()}</span>
                                        }
                                    </div>

                                }
                            </span>
                            <span className="user-name-area">
                                {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                                    <h6>Hi {localStorage.getItem('UserName')}!</h6>
                                }
                                {localStorage.getItem('UserName') === null &&
                                    <h6>Hi Sammi!</h6>
                                }
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-10 p-0 user-expense ">

                        <div className="total-to-spend">
                            <h6>Total To Spend</h6>
                            {this.state.contractSummary?.total_amount !== undefined &&
                                <h2>£{this.state.contractSummary.total_amount ? this.state.contractSummary.total_amount : '0.00'}</h2>
                            }
                            {this.state.contractSummary && this.state.contractSummary.total_amount == undefined &&
                                <h2>£{this.state.contractSummary.total_amount == undefined ? '0.00' : this.state.contractSummary.total_amount}</h2>
                            }

                            {this.state.contractSummary == null || this.state.contractSummary == '' &&
                                <h2>£{this.state.contractSummary == '' ? '0.00' : this.state.contractSummary.total_amount}</h2>
                            }
                            {/* {this.state.contractSummary == '' &&
                                <h2>£{this.state.contractSummary !== null ? '0.00':this.state.contractSummary.total_amount}</h2>
                            {this.state.contractSummary == null &&
                                <h2>£{this.state.contractSummary ? this.state.contractSummary.total_amount : '0.00'}</h2>

                            } */}
                        </div>
                        <div className="next-payment">

                            <h6>Next Payment</h6>
                            {this.state.contractSummary?.next_payment_amount !== undefined && this.state.contractSummary?.next_payment_amount !== '' && this.state.contractSummary?.next_payment_amount !== 0 &&

                                <h2>£{this.state.contractSummary.next_payment_amount !== undefined ? this.state.contractSummary.next_payment_amount : '0.00'}</h2>
                            }

                            {this.state.contractSummary && this.state.contractSummary.next_payment_amount == '' && this.state.contractSummary.next_payment_amount == 0 &&
                                <h2>£0.00</h2>
                            }
                            {this.state.contractSummary && this.state.contractSummary.next_payment_date == undefined &&
                                <h2>{this.state.contractSummary == undefined ? this.state.contractSummary.next_payment_date : ''}</h2>
                            }
                            {this.state.contractSummary == null || this.state.contractSummary == '' &&
                                <h2>£{this.state.contractSummary !== '' ? this.state.contractSummary.next_payment_amount : '0.00'}</h2>

                            }
                            {this.state.contractSummary == null || this.state.contractSummary == '' &&

                                <span>{this.state.contractSummary !== '' ? this.state.contractSummary.next_payment_date : ''}</span>
                            }
                            {this.state.contractSummary?.next_payment_date !== undefined &&
                                <span>{this.state.contractSummary !== null ? this.state.contractSummary.next_payment_date : ''}</span>
                            }

                        </div>


                    </div>
                </div>
            </div>
        </div>
    }

    mainTab = () => {
         return  <div className="col-md-5 ml-2 p-0 user-info">

            {this.state.profilePicture !== '' && this.state.profilePicture !== null &&
                <span className="user-image-area">
                    <img src={this.state.profilePicture} alt="User Image" />
                </span>
            }
            {this.state.profilePicture === null &&
                <div className="no-profile-image-home">
                    {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                        <span className="no-profile-image-letter-wallet">{localStorage.getItem('UserName').substring(0, 1).toUpperCase()}</span>
                    }
                </div>
            }
            <span className="user-name-area">
                {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                    <h6>Hi {localStorage.getItem('UserName')}!</h6>
                }
                {localStorage.getItem('UserName') === null &&
                    <h6>Hi!</h6>
                }

            </span>
        </div>
    }

    favcall = () => {
        setTimeout(() => {
            this.initialLoad('fav')
            .catch(error => console.error(error));
            
        }, 100);
    }

    displayUserAmountDetails = () => {
        return (
            <div className="col-md-7 p-0 pr-5 user-expense ">
                <div className="total-to-spend">
                    <h6>Total To Spend</h6>
                    <h2>£{this.state.contractSummary.total_amount ? this.state.contractSummary.total_amount : '0.00'}</h2>
                </div>
                <div className="next-payment">
                    <h6>Next Payment</h6>
                    <h2>£{this.state.contractSummary.next_payment_amount ? this.state.contractSummary.next_payment_amount : '0.00'}</h2>
                    <span>{this.state.contractSummary ? this.state.contractSummary.next_payment_date : ''}</span>
                </div>
            </div>
        );
    }

    analyticsclickEventForHomeApply = () => {
        analyticsClickEvent('home_apply_now', '')
    }

    analyticsclickEventForWallet = () => {
        analyticsClickEvent('wallet', '');
    }

    analyticsclickEventForViewAllPartners = () => {
        analyticsClickEvent('view_all', '');
    }

    render() {
        return (
            <div className="wrapper" >
                <Loader loaderStatus={this.state.isLoading} />
                {this.state.applicationStatus!="approved" &&
                    <section className="main">
                        <div className="container">
                            <div className="hero-section col-md-12 pt-5 pb-5">
                            <div className="row">
                                    <DeCommissioning ></DeCommissioning>
                                </div>
                                <div className="row">
                                    {localStorage.getItem('fromWhere') != 'skip' &&
                                        <div className={`${localStorage.getItem('blockedUsers') != 'true' ? 'col-md-5' : 'col-md-12'}  col-sm-12 col-xs-12 lr-item--hero on-scroll-hide"`}>
                                            <div className="hero-left">
                                                <div className="hero-left-top">
                                                     <div className="col-md-5 ml-2 p-0 user-info">

                                                        {this.state.profilePicture !== '' && this.state.profilePicture !== null &&
                                                            <span className="user-image-area">
                                                                <img src={this.state.profilePicture} alt="User Image" />
                                                            </span>
                                                        }
                                                        {this.state.profilePicture === null &&
                                                            <span className="user-image-area">
                                                                <div className="no-profile-image-home">
                                                                    {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                                                                        <span className="no-profile-image-letter-wallet">{localStorage.getItem('UserName').substring(0, 1).toUpperCase()}</span>
                                                                    }
                                                                </div>
                                                            </span>
                                                        }
                                                        <span className="user-name-area">
                                                            {localStorage.getItem('UserName') !== '' && localStorage.getItem('UserName') !== null &&
                                                                <h6>Hi {localStorage.getItem('UserName')}!</h6>
                                                            }
                                                            {localStorage.getItem('UserName') === null &&
                                                                <h6>Hi Sammi!</h6>
                                                            }

                                                        </span>
                                                    </div>
                                                    {this.displayUserAmountDetails()}
                                                </div>
                                                <div className="hero-left-bottom">
                                                    <Link to="/application" onClick={this.analyticsclickEventForHomeApply}>Apply Now <img src="images/right-arrow.svg" className="go-wallet" alt="next" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {localStorage.getItem('blockedUsers') != 'true' &&
                                        <div className={`${localStorage.getItem('fromWhere') != 'skip' ? 'col-md-7' : 'col-md-12'} col-sm-12 col-xs-12 lr-item--hero on-scroll-hide`}>
                                            <HomeBannerInactive applicationImage={this.state.applicationImage} />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        { Object.keys(this.stickyPosition()).length ? this.stickyPosition() : ''}
                    </section>
                }
                {this.state.applicationStatus==="approved" &&
                    <section className="main">
                        <div className="container">
                        <div className="hero-section col-md-12 pt-5 pb-5">
                        <div className="row">
                                <DeCommissioning></DeCommissioning>
                            </div>
                            <div className="row">
                                <div className="hero-section col-md-12 pt-5 pb-5">

                                    <div className="row m-0" >
                                        <div className="col-md-5 col-sm-12 col-xs-12 lr-item--hero on-scroll-hide">
                                            <div className="hero-left">
                                                <div className="hero-left-top">
                                                    {this.mainTab()}
                                                    {this.displayUserAmountDetails()}
                                                </div>
                                                <div className="hero-left-bottom">
                                                    <Link to="/wallet" onClick={this.analyticsclickEventForWallet} >Go To Wallet <img src="images/right-arrow.svg" className="go-wallet" alt="next" /></Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-7 col-sm-12 col-xs-12 lr-item--hero on-scroll-hide">
                                            <HomeBanner />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        {this.stickyPosition()}
                    </section>
                }
                <section className="featured-partners">
                    <div className="container">
                        <div className="feature-section">
                            <div className="d-flex">
                                <div className="featured-title-section">
                                    <h6 className="section-main-title">FEATURED PARTNERS</h6>
                                    <p className="section-sub-title">Just some of our amazing retail partners</p>
                                </div>
                                <div className="ml-auto">
                                    <Link to='/shop' className="view-all-btn" onClick={this.analyticsclickEventForViewAllPartners}>View All</Link>
                                </div>
                            </div>
                            <div className="featured-partners-list">
                                {this.state.FeaturedPartnerSliderData &&
                                    <SimpleSlider items={this.state.FeaturedPartnerSliderData}  favCall={this.favcall} />
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="featured-partners">
                    <div className="container">
                        <div className="feature-section">
                            <div className="featured-title-section">
                                <h6 className="section-main-title">STORE CATEGORIES</h6>
                                <p className="section-sub-title">Find all your favourite little and big things, online and instore at all the big brands.</p>
                            </div>
                            <div className="featured-partners-list">
                                {this.state.setUniqueIndustries &&
                                    <CategorySlider items={this.state.setUniqueIndustries}/>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.setUniqueIndustries && this.state.AllPartnerSliderData && this.state.setUniqueIndustries &&
                    this.IndustriesBased()
                }
                <div className="chat-box-section">
                    <div className="chat-box">

                        <button type="button" id='chat' onClick={() => { analyticsClickEvent('support_mail', ''); window.open('https://mail.google.com/mail/u/0/?fs=1&to=' + process.env.REACT_APP_HUMM_SUPPORT_EMAIL_ID + '&su=Enquiry&tf=cm') }} className="chat-box-btn"><img src="images/chat-icon.svg" alt="chat-box" /></button>

                    </div>
                </div>

            </div>
        );
    }

}

export const mapStateToProps = (state) => {
    return {

        profilePicture: state.dataStore.profilePicture,
        allpartnerListSearchStore: state.dataStore.allpartnerListSearchStore,
        contractSummary: state.dataStore.contractSummary,
        storePartnerImage: state.dataStore.storePartnerImage,
    };
};



export const mapDispatchToProps = (dispatch) => {
    return {
        getPartnerSearch: (formData) => {
            return dispatch(getPartnerSearch(formData));
        },
        getContractSummaryDetails: (summary) => {
            return dispatch({
                type: 'SET_CONTRACT_SUMMARY',
                value: summary
            });
        },
        storePartnerImagefunction:(formData)=>{
            return dispatch({
                type: 'STOREPARTNERIMAGE',
                value: formData
            });
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))