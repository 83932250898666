import React from 'react';
import { verifyToken } from '../../action/application/veifyToken'
import DocumentVerifyMobile from '../application/documentVerifyMobile'
import { PropTypes } from 'prop-types';
import Loader from '../loader/loader';

class HummVerificationPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            redirect:'',
            checkPageLoad:'',
            loading: false
        }
    }

    handleVerifyTokenResponseResult = (verifyTokenResult) => {
        if(verifyTokenResult.status===200){
            this.setState({redirect:'true'})
            localStorage.setItem('pageLanded','yes');

            localStorage.setItem('email',verifyTokenResult.data.data.decoded.data.email);
            localStorage.setItem('applicationId',verifyTokenResult.data.data.decoded.data.application_id);
            localStorage.setItem('sessionId', verifyTokenResult.data.data.decoded.data.session_id)

            localStorage.setItem('userLoginType', verifyTokenResult.data.data.decoded.data.userlogintype);
        }else{
            this.setState({redirect:'false'})
            localStorage.setItem('pageLanded','No');
            this.setState({checkPageLoad:true})
            }
    }

    componentDidMount(){
        this.verificationData();
    }

    verificationData=async()=>{
        let jwt=this.props.match.params.doc;
        if(jwt!==localStorage.getItem('handOFF')){
            localStorage.setItem('applicationStatus','')
        }
        localStorage.setItem('handOFF',jwt);

        const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i;

        // Extract UUID using the regular expression
        const extractedUuid = jwt.match(uuidPattern);

        // Check if a UUID is found
        if (extractedUuid) {
            const jwtToken = extractedUuid[0];
            const obj={
                "token": jwtToken
            }

            if(localStorage.getItem('applicationStatus')===''){
                const verifyTokenResult= await verifyToken(obj);
                console.log('application in')
                
                this.handleVerifyTokenResponseResult(verifyTokenResult)
            }else{
                this.setState({redirect: "true"})
            }
        }
        else {
            let responseData=JSON.parse(atob(jwt.split(".")[1]));
            const obj={
                "email":responseData.data.email,
                "application_id":responseData.data.application_id,
                "token":jwt
            }
            localStorage.setItem('email',responseData.data.email);
            localStorage.setItem('applicationId',responseData.data.application_id);
            localStorage.setItem('sessionId', responseData.data.session_id)
            if(localStorage.getItem('applicationStatus')===''){
                const result= await verifyToken(obj);
                console.log('application in')
            if(result.status===200){
                this.setState({redirect:'true'})
                localStorage.setItem('pageLanded','yes');
                localStorage.setItem('userLoginType',result.data.data.decoded.data.userlogintype);
            }else{
                this.setState({redirect:'false'})
                this.setState({checkPageLoad:true})
                localStorage.setItem('pageLanded','No');
                }
            }else{
                this.setState({redirect:'true'})
            }
        }
    }

    handleRetryLoaderStatus = (value) => {
        console.log('handoff_handleRetryLoaderStatus',value);
        // this.props.handleRetryLoader(value);
        this.setState({loading: value})
      }

    render(){
        if (this.state.redirect === 'true') {
            return(
                <div>
                    <Loader loaderStatus={this.state.loading} />
                    <DocumentVerifyMobile handleRetryLoader={this.handleRetryLoaderStatus}/>
                </div>
            );
        }else if(this.state.redirect === 'false'){
            return(
                <div className="appfrom-section">
                <div className="mit-container">
                    <div className="complete-section">
                        <div className="logo-area">
                            <img src="images/humm-logo.svg" alt="Humm" />
                        </div>
                        <div className="complete-content">
                            <p>Token Expired</p>
                </div>
            </div>
        </div>
    </div>
    
            );
        }else{
            return(
                <div>
                    <p></p>
                </div>
    
            );
        }
       
    }


}

HummVerificationPage.propTypes = {
    match: PropTypes.object,
}

export default HummVerificationPage;