import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId} from 'firebase/analytics';


// export const fireBaseLogEvent = (event, paramsData) => {
//     const analytics = firebase.analytics();
//     analytics.setAnalyticsCollectionEnabled(true);
//     analytics.logEvent(event, paramsData);
// }

let firebaseConfig = {
    apiKey: process.env.REACT_APP_AUTHAPIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};
const app = initializeApp(firebaseConfig);

export const firebaseLoadingEvent = (event) => {
    let pathname = window.location.pathname;
    if(pathname !== '' && pathname !== null && pathname !== undefined){
    var screenName = pathname.split('/');
    }
    let params = {
        page_title: screenName[1]
    }
    const analytics = getAnalytics(app);
    // analytics.setAnalyticsCollectionEnabled(true);
    logEvent(analytics, event, params);
}

export const analyticsScreenViewEvent = () => {
    let pathname = window.location.pathname;
    let changeScreenname = '';
    if(pathname !== '' && pathname !== null && pathname !== undefined){
    var screenName = pathname.split('/');
    changeScreenname = screenName[1].split('-').join('_')
    }
    changeScreenname = changeScreenname + '_viewed';
    let params = {
        page_title: screenName[1],
        user_click_event: changeScreenname
    }
    const analytics = getAnalytics(app);
    logEvent(analytics, changeScreenname, params);
}

export const analyticsLoginSuccessEvent = (event, partnername) => {
    let pathname = window.location.pathname;
    if(pathname !== '' && pathname !== null && pathname !== undefined){
    var screenName = pathname.split('/');
    }
    let clickEvent = '';
    if (event !== '' && event !== null && event !== undefined) {
        if (typeof (event) == 'string') {
            clickEvent = event.toLocaleLowerCase();
        } else {
            clickEvent = event;
        }
        clickEvent = clickEvent.split(' ').join('_');
    }
    let pn = ''
    if (partnername !== '' && partnername !== null && partnername !== undefined) {
        // if (typeof (partnername) == 'string') {
        //     pn = partnername.toLocaleLowerCase();
        // } else {
        pn = partnername;
        // }
    }
    let params = {
        page_title: screenName[1],
        data: pn,
        user_click_event: clickEvent
    }
    const analytics = getAnalytics(app);
    logEvent(analytics, clickEvent, params);
    if(localStorage.getItem('sessionId') !== null){
        setUserId(analytics, localStorage.getItem('sessionId'))
    }
    
}

export const analyticsClickEvent = (event, partnername) => {
    let pathname = window.location.pathname;
    if(pathname !== '' && pathname !== null && pathname !== undefined){
    var screenName = pathname.split('/');
    }
    let clickEvent = '';
    if (event !== '' && event !== null && event !== undefined) {
        if (typeof (event) == 'string') {
            clickEvent = event.toLocaleLowerCase() + '_clicked';
        } else {
            clickEvent = event + '_clicked';
        }
        clickEvent = clickEvent.split(' ').join('_');
    }
    let pn = '';
    if (partnername !== '' && partnername !== null && partnername !== undefined) {
        pn = partnername;
    }
    let params = {
        page_title: screenName[1],
        data: pn,
        user_click_event: clickEvent
    }
    const analytics = getAnalytics(app);
    logEvent(analytics, clickEvent, params);
    if(localStorage.getItem('sessionId') !== null){
        setUserId(analytics, localStorage.getItem('sessionId'))
    }
    
}

export const analyticsTypeEvent = (fieldname, data) => {
    let pathname = window.location.pathname;
    if(pathname !== '' && pathname !== null && pathname !== undefined){
    var screenName = pathname.split('/');
    }
    let typeEvent = '';
    if (fieldname !== '' && fieldname !== null && fieldname !== undefined) {
        if (typeof (fieldname) == 'string') {
            typeEvent = 'type_' + fieldname.toLocaleLowerCase() + '_box';
        } else {
            typeEvent = fieldname + '_box';
        }
    }

    let params = {
        page_title: screenName[1],
        user_click_event: typeEvent
    }
    const analytics = getAnalytics(app);
    logEvent(analytics, typeEvent, params);
}

export const analyticsChangeEvent = (fieldname,partnername) => {
    let pathname = window.location.pathname;
    if(pathname !== '' && pathname !== null && pathname !== undefined){
    var screenName = pathname.split('/');
    }
    let selectEvent = '';
    if (fieldname !== '' && fieldname !== null && fieldname !== undefined) {
        if (typeof (fieldname) == 'string') {
            selectEvent = fieldname.toLocaleLowerCase() + '_selected';
        } else {
            selectEvent = fieldname + '_selected';
        }
        selectEvent = selectEvent.split(' ').join('_');
    }

    let pn = ''
    if (partnername !== '' && partnername !== null && partnername !== undefined) {
        // if (typeof (partnername) == 'string') {
        //     pn = partnername.toLocaleLowerCase();
        // } else {
        pn = partnername;
        // }
    }
    let params = {
        page_title: screenName[1],
        data: pn,
        user_select_event: selectEvent
    }
    const analytics = getAnalytics(app);
    logEvent(analytics, selectEvent, params);
    if(localStorage.getItem('sessionId') !== null){
        setUserId(analytics, localStorage.getItem('sessionId'))
    }
}