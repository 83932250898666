import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { getAvatar,getApplicationImage } from '../../../action/avatar/getAvatar';
import {getUserBlockStatus} from '../../../action/auth/login'
import { analyticsScreenViewEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';

export class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:false,
            profilePicture:props.profilePicture,
            customPath:'/home',
            applicationImage: 'images/home-active-contract-bg.png'

          }
    }

    async componentDidMount() {
        // analyticsScreenViewEvent();
        localStorage.setItem('blockedUsers','');
    if(localStorage.getItem('fromWhere')!='skip'){
       await this.initialLoad();
    }else{
        const applicationImage = await getApplicationImage();
            if (applicationImage && applicationImage.status === 201) {
                
                if (Array.isArray(applicationImage.data.image) && applicationImage.data.image.length !== 0) {
                    let appImg = applicationImage.data.image[1];
                    localStorage.setItem('applicationImage', appImg);
                    this.setState({applicationImage:appImg});
                } else {
                    this.setState({applicationImage:'images/home-active-contract-bg.png'});
                }
    
            } else {
                this.setState({applicationImage:'images/home-active-contract-bg.png'});
            }
    }
    if(localStorage.getItem('fromWhere')!='skip'){
        
    if(localStorage.getItem('sessionId')) {
        const getBlockedUser=await getUserBlockStatus();
            if (getBlockedUser && Array.isArray(getBlockedUser.data.data) && getBlockedUser.data.data.length !== 0) {
                localStorage.setItem('blockedUsers', getBlockedUser.data.data.blockstatus);
            }
        }
    }
       setTimeout(() => {
        this.setState({redirect:true});
      }, 300);


    }

    initialLoad=async()=>{
        const obj={
            email:localStorage.getItem('email')
        }

       const imageResult = await getAvatar(obj);
       const applicationImage = await getApplicationImage();
       if(imageResult && imageResult.data.statusCode===200){
        localStorage.setItem('UserName',imageResult.data.data.first_name+' '+imageResult.data.data.last_name);
         if(imageResult.data.data.image_path!==undefined){
            let userPic ='data:image/jpeg;base64,'+imageResult.data.data.image_path
            this.props.setProfilePic(userPic);
        }
         else{
            let userPic= localStorage.getItem("profilePicture");
           if(userPic!=null){
            this.props.setProfilePic(userPic);
            }
            if(localStorage.getItem('fromWhere')==='skip'){
                let defaultImage ='images/profile-avatar.png';

                this.props.setProfilePic(defaultImage);
            }
         }

        }
        
        if (applicationImage && applicationImage.status === 201) {
            if (Array.isArray(applicationImage.data.image) && applicationImage.data.image.length !== 0) {
                let appImg = applicationImage.data.image[1];
                this.setState({applicationImage:appImg});
            } else {
                this.setState({applicationImage:'images/home-active-contract-bg.png'});
            }

        } else {
            this.setState({applicationImage:'images/home-active-contract-bg.png'});
        }
     
       
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.profilePicture !== nextProps.profilePicture) {
          return {
            profilePicture: nextProps.profilePicture
          };
        }
        
        return null;
    }
   
    render() { 
        if (this.state.redirect) {
            return <Redirect  to={{pathname: '/home',state:{applicationImage:this.state.applicationImage}}}/>;
        }
        return ( 
            <div className="layout-fixed">
            <div className="wrapper welcome-page">
                <div className="logo">
                    <img src="images/logo.svg" className="img-fluid" alt="welcome logo"/>
                </div>
                <div className="welcome-content">
                    <p>Awesome, welcome to humm <br></br>and happy shopping!</p>
                </div>
            </div>
          </div> 
         );
    }
}



export const mapStateToProps = (state) => {
    return {
        profilePicture:state.dataStore.profilePicture
    };
   };


 export const mapDispatchToProps = (dispatch) => {
    return {
        setProfilePic:(userPic)=>{
            return dispatch({
                type: 'SET_PROFILE_PIC',
                value: userPic
            });
        }
        
    }
   }
export default connect(mapStateToProps,mapDispatchToProps)(Welcome)