import React from "react";
import { Redirect } from 'react-router-dom';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import Reaptcha from 'reaptcha';
import Loader from "../../loader/loader";
import { GTMServerSubmitError } from "../../../utils/GtmDatalayer";
import { appleUserSendMobileOTP, sendMobileOTP, verifyMobileOTP } from "../../../action/application/mobileOTP";
import parse from 'html-react-parser';
import { handleStatusCode } from "../../../utils/handleStatusCode";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { PropTypes } from 'prop-types';

export class AppleUserMobileVerification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            phoneNo: '',
            otpVerifyStatus: '',
            otpStatusCode: '',
            serverErrorMessage: '',
            redirect: false,
            disableField: false,
            userId: props.userId,
            displayPhoneError: '',
            otpErrorMessage: '',
            code: '',
            statusCodeErrorForResendCode: '',
            disableVerifyOkButton: false,
            verfiyOTPFlag: false,
            verificationMessage: '',
            redirectURL: '',
            captchaReady: false,
            verified: false,
            resendFlag: false,
            phoneNumberFlag: false,
            popupButtonLabel: '',
            handleChangeErrors:{
                emailError:'',
              }
        }
        this.reCaptchaRef = null
      }


      componentDidMount() {
    
        window.onpopstate = e => {
            this.removeFromHistory();
        }
    }


      handleChangeInputForPhone = (event)=> {
        console.log('handleChangeInputForPhone',event.target.value);
        if (event.target.value.length >= 12) {
          return false
        }      
        this.handlingValidations(event.target.value);
      }

      handlingValidations = (value) => {
        console.log('handlingValidations',value);
        let mystring = value.substring(0, 2);
        let error = this.state.displayPhoneError;
        if (value === '') {
            error = 'Enter a mobile number'
            this.setState({ displayPhoneError: error })
        } else if (value.length !== 11 || !/^\d+$/.test(value)) {
            error = 'Invalid UK mobile number format'
            this.setState({ displayPhoneError: error})
        } else if (mystring !== '07') {
            error= 'Enter a valid mobile number'
            this.setState({ displayPhoneError: error})
        } else {
            error = ''
            this.setState({ displayPhoneError: error})
        }
        this.setState({ phoneNo: value });
    
      }

      onLoading = () => {
        this.setState({
          captchaReady: true
        });
    };

    onVerifying = (getToken) => {

        // If the reCaptcha is loaded and token is generated, save the token to the state reCaptchaToken
        if(getToken != null && getToken !== '' && this.state.captchaReady){
            this.setState({verified: true})
            this.setState({reCaptchaToken: getToken}, () => {
                
                this.handlePhoneNumberFlag();

                if(this.state.resendFlag){
                    this.mobileResendCodeForOTP()
                    .catch(error => console.error(error));
                    return false;
                }

                this.handleVerifyOTPFlag();
            });

        }
        else {
            this.setState({verified: false})
        }

    }

    handleVerifyOTPFlag = () => {
        if(this.state.verfiyOTPFlag && this.state.code){
                this.makeAPICallForVerifyOTP()
                .catch(error => console.error(error));
                return false;
        }
    }

    makeAPICallForVerifyOTP = async() => {
        analyticsClickEvent('register_verifySSO_mobile_popup_ok','')

        this.setState({disablePopupRegistration:'disable-popup'});

        const payloadObject = {
            "Mobile_Number": this.state.phoneNo,
            "verify_code": this.state.code,
            "recaptchaToken": this.state.reCaptchaToken,
            "Application_Channel": "Web"

        }
    
        this.setState({disableVerifyOkButton: true});
        const userVerifyOtpResult = await verifyMobileOTP(payloadObject);
        console.log('userVerifyOtpResult',userVerifyOtpResult);
        this.setState({verifyOTP: false});
        if(userVerifyOtpResult.status == 200){
            this.setState({validateStatusCode:userVerifyOtpResult.status,verificationMessage: userVerifyOtpResult.data.message, codeSuccess: '', statusCodeErrorForResendCode: '',otpVerify: true},() => {
                this.hidePopup('#mobileVerifyModal');
                window.$('#mobileSuccessModal').modal('show')
            });

            setTimeout(() => {
                this.hidePopup('#mobileSuccessModal');
                this.props.history.push("/forgot-password");
              }, 4000);            
        }
        else {
            this.setState({disableVerifyOkButton: false, codeSuccess: '',statusCodeErrorForResendCode: '', disableField: false});
            this.setState({otpVerify: false,
            otpVerifyStatus: userVerifyOtpResult.status,codeError: userVerifyOtpResult.data.message});
            this.setState({ loading: false })
            this.setState({ otpErrorMessage: userVerifyOtpResult.data.message })
        }
    }

    hidePopup = (modalName) => {
        // Reset the variables on popup to its initial value when the popup is dismissed
        this.setState({code: '', disableVerifyOkButton: false, codeError: '', codeSuccess: '', 
        statusCodeErrorForResendCode: '', disableField: false});
    
        window.$(modalName).modal("hide");
      }

    handlePhoneNumberFlag = () => {
        if(this.state.phoneNumberFlag){
            this.makeAPICallForAppleUserPhoneNumber().catch(error => console.error(error));
            return false;
        }
    }


    makeAPICallForAppleUserPhoneNumber = async() => {
        const cookie = new Cookies();
        let requestObject = {
            "Mobile_Number": this.state.phoneNo,
            "recaptchaToken": this.state.reCaptchaToken,
            "Application_Channel": "Web"
        }
        const userSendOTPResult = await appleUserSendMobileOTP(requestObject);
        console.log('userSendOTPResult',userSendOTPResult);
        this.setState({phoneNumberFlag: false});
        if(userSendOTPResult.data.statusCode === 200 && userSendOTPResult.data.data.userType === 'Hide My Mail'){
            this.setState({loading: false,code:''},() => {
                window.$('#mobileVerifyModal').modal('show');
            });
            cookie.set('userType',userSendOTPResult.data.data.userType)
            cookie.set('apple_user_mobile_number',this.state.phoneNo);
            console.log('state_mobile_no',this.state.phoneNo);
            let input = {};
            input['appleUserMobileNumber'] = this.state.phoneNo;
            console.log('props_set_vale',input);
            this.props.setAppleUserMobileNumber(this.state.phoneNo);
            return false
        }else if(userSendOTPResult.data.statusCode === 200 && userSendOTPResult.data.data.userType === 'Show My Mail'){
            this.setState({validateStatusCode: userSendOTPResult.data.statusCode, loading: false,serverErrorMessage: userSendOTPResult.data.message})
            window.$('#mobileErrorModal').modal('show');
        }else {
            this.setState({serverErrorMessage: userSendOTPResult.data.message,validateStatusCode: userSendOTPResult.data.statusCode, loading: false})
            
            window.$('#mobileErrorModal').modal('show');
        }
    }



      onlyAcceptInputs_phone = (e) => {
        if (!/\d/.test(e.clipboardData.getData('Text'))) {
            e.preventDefault();
        }  
        if (/[a-zA-Z]/.test(e.clipboardData.getData('Text'))) {
            e.preventDefault();
        }
        
    }

    onBlurPushGTMError = () => {
        if(this.state.displayPhoneError !== ''){
            GTMServerSubmitError('Mobile Verification', 'client',this.state.displayPhoneError, 1, 3, '1 : 3');
            return false;
        }
    }

    removeFromHistory() {

        if (window.$('#forgotModal').hasClass('show') === false) {
            window.$('#forgotModal').modal('hide');
            window.$('.modal-backdrop').removeClass('show');
            window.$('body').removeClass('modal-open');
            window.$('.modal-backdrop').remove();
        }
    }

    recaptchaForSubmit = (event) => {

        if (window.$('#appleUserForgotPasswordErrorModal').hasClass('show') === true) {
            window.$('#appleUserForgotPasswordErrorModal').modal('hide');
        }

        analyticsClickEvent('apple_user_forgot_password_send_button','')
        event.preventDefault();

        this.setState({disable:'disabled', forgotPasswordFlag: true, recaptchaToken: ''});
        this.setState({loading: true})
        if(this.reCaptchaRef !== null && this.reCaptchaRef !== undefined){
            this.reCaptchaRef.execute();
        }
    }

    appleUserBackgroundImage=()=>{
            return <div className="col-md-6 col-sm-12 col-xs-12 section-left p-0">
            <div className="leftLogin-img-sec">
                <img src="images/humm-login-bg.jpg" alt="Background" />
            </div>
            </div>;
    }

    handlePhoneSubmit = () => {
        analyticsClickEvent('mobile_verify_mobile_send','')
        if(this.state.phoneNo == '' ){
            this.setState({displayPhoneError: 'Enter a mobile number'})
            return false
        } else if(this.state.displayPhoneError !== '') {
            return false
        }
        else {

            this.reCaptchaRef.execute();
            this.setState({displayPhoneError: '', phoneNumberFlag: true, codeSuccess: '', loading: true});

        }
      }

      appleUserDisplayResendSMS = () => {
        if(this.state.disableField) {
            return (
                <a onKeyDown={(e) => { e.preventDefault(); }}
                onClick={(e) => e.preventDefault()} id="resend-code" className="font-weight-bold">Resend Code</a>
            );
        }
        else {
            return(
                <a onKeyDown={(e) => { e.preventDefault(); }}
                onClick={(e) => this.handleRecaptchaForResendOTP(e)} id="resend-code" className="font-weight-bold">Resend Code</a>
            );
        }

    }

    handleRecaptchaForResendOTP = (event) => {
        let text = event.target.innerText.split(' ').join('_');
        analyticsClickEvent(text.toLocaleLowerCase(),'')

        this.setState({reCaptchaToken: ''})
    
        this.reCaptchaRef.execute();
        this.setState({resendFlag: true})
      }

      appleUserDisplayChangeMobileNumber = () => {
        if(this.state.disableField){
            return (
                <a className="emailChange" onKeyDown={(e) => { e.preventDefault(); }} onClick={(e)=> e.preventDefault()}>
                                Wrong Number? Change here</a>
            );
        }
        else {
            return(
                <a className="emailChange" onKeyDown={(e) => { e.preventDefault(); }} onClick={()=> this.appleUserChangePhoneNo("#mobileVerifyModal")}>
                    Wrong Number? Change here</a>
            )
        }
    }

    appleUserRedirectErrorMessagePopupClicked = () => {
        analyticsClickEvent('ok','')
        window.$('#commonModel').modal('hide');
        window.$('#userBalanceShowModel').modal('hide')
        window.$('.modal-backdrop').removeClass('show');
        window.$('body').removeClass('modal-open');
        window.$('.modal-backdrop').remove();
        this.redirectionForErrorPopupUpIfRedirectURLExists();
     }

     redirectionForErrorPopupUpIfRedirectURLExists = () => {
        if(this.state.redirectURL !== '' && this.state.redirectURL !== undefined) {
          this.setState({ loading: true })
          window.location.href = this.state.redirectURL
          if(localStorage.getItem('fromWhere') === 'apply_now'){
            this.props.history.push("/login");
            window.location.reload(false)
          }
          else {
            this.props.history.push("/application");
          } 
        }
        else if(this.state.validateStatusCode === 200){
            this.props.history.push("/forgot-password");
            window.location.reload(false)
        }
        else {
            this.props.history.push("/login");
            window.location.reload(false)
        }      
        
      }


      handleChangeOtp = async(event) =>{
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(window.$('#registerModal').hasClass('show') && value.length >= 6) {
            return false
        }
        else if(window.$('#mobileVerifyModal').hasClass('show')) {
          if (value.length >= 7) {
            return false
          }
        }

        this.setState({ disableRegistration: '' })
        this.setState({ disablePopupRegistration: '' })

        this.setState({ [name]: value });
        if (name === 'code') {
            if (!/\d/.test(value)) {
                event.preventDefault();
            }
        }
    }

    mobileOTPVerify = () => {
        if(window.$('#registerModal').hasClass('show') === true){
            analyticsClickEvent('email_verify_ok_button','')
        }

        if(window.$('#mobileVerifyModal').hasClass('show') === true){
            analyticsClickEvent('mobile_verify_ok_button','')
        }
        
        this.setState({ disableVerifyOkButton: true,codeError: ''})

        if (this.state.code === '') {
            this.setState({ loading: false,
                disableVerifyOkButton: false, codeSuccess: '',codeError: 'Verification code is required.' });

            if (window.$('#registerModal').hasClass('show') === true) {
                GTMServerSubmitError('Email Verification', 'client',"Verification code is required.", 1, 3, '1 : 3');
            } else {
                GTMServerSubmitError('Mobile Verification', 'client',"Verification code is required.", 1, 3, '1 : 3');
            }

            this.setState({ loading: false });
            return false;
        }
        else {
            this.reCaptchaRef.execute();
            this.setState({disableField: true,verfiyOTPFlag: true});
        }
    }

    mobileResendCodeForOTP = async(_e) => {
        const requestToSendMobileOTP = {
            "Mobile_Number": this.state.phoneNo,
            "Application_Channel": "Web",
            "recaptchaToken": this.state.reCaptchaToken
        }

        if(this.state.userToken !== '' && this.state.userToken !== undefined) {
            requestToSendMobileOTP.dataToken = this.state.userToken;
        }

        const resultFromSendMobileOTP = await sendMobileOTP(requestToSendMobileOTP);

        this.setState({resendFlag: false});

        if (resultFromSendMobileOTP.data.statusCode === 200) {
            this.setState({ codeSuccess: resultFromSendMobileOTP.data.message, codeError: ''})
            this.setState({ statusCodeErrorForResendCode: ''})
        }
        /* check whether the status code received matches in the list
        using handleStatusCode method and set the error message in variable statusCodeErrorForResendCode
        */
        else if(handleStatusCode(resultFromSendMobileOTP.data.statusCode)){
            this.setState({ codeSuccess: '', codeError: ''});
            this.setState({ statusCodeErrorForResendCode: resultFromSendMobileOTP.data.message});
        }
    }

    appleUserChangePhoneNo = (modalName) => {
        this.setState({code: '', codeError: '', codeSuccess: '', statusCodeErrorForResendCode: '',verificationMessage: ''});
        this.setState({disableField: false, disableVerifyOkButton: false});
        window.$(modalName).modal("hide");
    }


    render(){
        if (this.state.redirect) {
            return <Redirect push to="/forgot-password-code" />;
        }
        return(
            <div className="layout-fixed">
            <div className="wrapper login-page scroll-sec-fix">
                <Loader loaderStatus={this.state.loading} />
                {this.appleUserBackgroundImage()}

                {/* Apple user mobile number input screen */}
                <div className="col-md-6 col-sm-12 col-xs-12 section-right p-0">
                    <div className="section-form">
                        <div className="form-header">
                            <span className="section-logo">
                                <img src="images/logo.svg" alt="logo"/>
                            </span>
                            <span className="section-heading">
                                <h4>Verify your mobile number for
setup your password.</h4>
                            </span>
                        </div>
                        <div className="form-body">
                            <form name="loginForm" id="login-form" className="log-form">
                                <div className="error-user">
                                    <div className="form-group floating-label-group">
                                        <input type="text" name='phoneNo' className="form-control" id="phoneNumber"
                                            autoComplete='none'
                                            value={this.state.phoneNo} 
                                            placeholder=" " required
                                            onChange={(e) => this.handleChangeInputForPhone(e)}
                                            onPaste={(e) => this.onlyAcceptInputs_phone(e)}
                                            onBlur={ () => this.onBlurPushGTMError() }
                                            onClick={(_e)=>analyticsClickEvent('mobile_number','')}/>
                                        <label className="floating-label">Mobile</label>
                                    </div>
                                    <p className="error-message">{this.state.displayPhoneError !== '' &&
                                            <span style={{ color: 'Red' }}>{this.state.displayPhoneError}</span>
                                            }
                                            </p> 
                                </div>
                                <Reaptcha
                                            ref={event => (this.reCaptchaRef = event)}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            badge="bottomright"
                                            onLoad={this.onLoading}
                                            onVerify={this.onVerifying}
                                        />
                                <div className="form-group mt-4 mb-0">
                                        <input type="button" className="btn send-button form-control text-center" required id="submit" value="Next" onClick={(e) => this.handlePhoneSubmit()} />
                                    </div>  
                            </form>
                        </div>
                    </div>
                </div>

                {/* Apple user mobile number OTP verify screen */}
                <div className="modal fade" tabIndex="-1" id="mobileVerifyModal" data-keyboard="false" aria-labelledby="mobileModalLabel" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" className="img-fluid" alt="logo" />
                                </div>
                                <button type="button" className="close" aria-label="Close" onClick={()=> this.appleUserChangePhoneNo("#mobileVerifyModal")}
                                    style={{position: "absolute", top: "0", right: "0"}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="mobileVerifyModalLabel">Verification SMS</h5>
                                <p className="mb-1 mt-2">Now let's verify your mobile number.</p>
                                <p className='bold-text'>We sent a code to <b>{this.state.phoneNo}</b>, enter it below.</p>
                                <input 
                                className="form-control" 
                                disabled={this.state.disableField}
                                type="text" 
                                id="code" 
                                name="code" 
                                placeholder="Code"
                                value={this.state.code}
                                onPaste={(event) => { this.onlyAcceptInputs_phone(event) }} required
                                onChange={(event) => this.handleChangeOtp(event)}  />
                            </div>
                            {this.state.codeError !== '' &&
                                <p className="text-center modal-error-msg">
                                    <span className='error-message' >{this.state.codeError}</span>
                                </p>
                            }
                            <p> Didn't receive SMS?</p>
                            <p className="text-center link--block mb-0">
                                {this.appleUserDisplayResendSMS()}

                                {this.appleUserDisplayChangeMobileNumber()}
                            </p>
                            <p className="text-center modal-error-msg mt-3 mb-1">
                            {/* apple user Display the success message when the message is not empty */}
                            {this.state.codeSuccess !== '' &&
                                <span className="otp-message">{this.state.codeSuccess}</span>
                            }
                            
                            {/* apple user Display the error message when the message is not empty */}
                            {this.state.statusCodeErrorForResendCode !== '' && 
                                <span style={{color: '#ff0000'}}>{this.state.statusCodeErrorForResendCode}</span>
                            }

                                <span className='succes-message mb-0'>{parse(this.state.verificationMessage)}</span>
                            </p>

                            <div className="modal-footer">
                                <button type="submit" id='moible-redirect-screen' className={`${this.state.disablePopupRegistration === 'disable-popup' ? 'btn btn-primary disable-popup' : 'btn btn-primary'}`} disabled={this.state.disableVerifyOkButton} onClick={(e) => this.mobileOTPVerify(e)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* apple user server error popup modal */}
                <div tabIndex="-1" data-keyboard="false" className="modal fade model-error" id="mobileErrorModal" data-backdrop="static" aria-labelledby="serverErrorShowModellLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>
                                {/* apple user Added the margin left and right spacing to message displayed in popup */}
                                <p className="text-center mt-4 mx-3">{parse(this.state.serverErrorMessage)}</p>
                            </div>

                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.appleUserRedirectErrorMessagePopupClicked}>{this.state.popupButtonLabel !=='' ? this.state.popupButtonLabel : `Ok`}</button>
                            </div>


                        </div>
                    </div>
                </div>
                
                 {/* Apple user mobile number OTP verify success screen */}
                <div className="modal fade" id="mobileSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="mobileSuccessModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-body pt-0 pb-0">
                          <div className="logo">
                              <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                          </div>

                          <h5 className="modal-title text-center" id="mobileSuccessModalLabel">
                            Confirmed
                          </h5>

                        {this.state.verificationMessage !== '' &&
                          <p className='sucess-message text-center'>
                            {parse(this.state.verificationMessage)}
                          </p>
                        }
                      </div>
                    </div>
              </div>
           </div>

                <div className="modal fade model-error" id="appleUserForgotPasswordErrorModal" tabIndex="-1" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                    <div className="modal-dialog" >
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid"/>
                                </div>
                                <p className="mt-4">{this.state.serverErrorMessage}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button type="button" className="btn btn-primary"  onClick={this.recaptchaForSubmit}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

}

export const mapStateToProps = (state) => {
    return {
        userId: state.dataStore.userId
    };
    
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setAppleUserMobileNumber: (data) => {
            console.log('send_setAppleUserMobileNumber',data);
            return dispatch({
              type: 'SET_APPLE_USER_MOBILE_NUMBER',
              value: data
          });
        }
    }
}

AppleUserMobileVerification.propTypes = {
    userId: PropTypes.string,
    setAppleUserMobileNumber: PropTypes.func,
    history: PropTypes.object

}
export default connect(mapStateToProps,mapDispatchToProps)(AppleUserMobileVerification);