
export const MobileTermsPage = () => {
    return (
        <div className="container mt-4 mobile-terms-page mb-4">
            <center>
                <img src="images/modal-logo.svg" alt="Humm bird logo" className="d-inline-block mt-0 mb-3" />
                <h2 className="font-weight-bold mobile-terms-page--heading">Mobile Terms</h2>
                <p className="font-weight-bold mobile-terms-page--subheading">PLEASE READ THESE LICENCE TERMS CAREFULLY</p>
                
                <p className="">THIS IS A USER AGREEMENT BETWEEN HUMM GROUP (REGISTERED ADDRESS AT 3RD FLOOR, 2-4 WELLINGTON STREET, BELFAST CITY, BELFAST, BT16HT, UK, COMPANY NUMBER OF NI675430, (“WE”, “OUR” OR “US”) AND THE INDIVIDUAL USER OR YOU (“YOU”, “YOUR”).</p>

                <p className="font-weight-bold mobile-terms-page--subheading">
                PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE HUMM GROUP APP. THESE TERMS TELL YOU WHO WE ARE AND THE BASIS ON WHICH WE PROVIDE THE HUMM GROUP APP TO YOU. BY CLICKING ON THE “ACCEPT” BUTTON BELOW YOU AGREE TO THESE TERMS WHICH WILL BIND YOU.
                </p>
            
            </center>

                <p>1.  <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">What this agreement does</span> </p>
                <p>1.1 <span className="d-inline ml-5">We license you to use:</span></p>
                <p>
                <ul> 
                    <li>
                    the Humm Group mobile application software, functionality, the data supplied with the software and any updates or supplements to it (together, the “App”); and
                    </li>

                    <li>
                    any related online or electronic documentation (Documentation), as permitted in these terms. </li>
                </ul>
                </p>

                <p>2. <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">Your privacy</span></p>
                <p>2.1 <span className="d-inline ml-5">Under data protection legislation, we are required to provide you with certain information including who we are, how we process your personal data and for what purposes and your rights in relation to your personal data and how to exercise them. This information is provided at www.shophumm.com/uk/privacy-policy/ and it is important that you read that information.</span></p>

                <p>3. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Additional terms applying to you
                    </span>
                </p>
                <p>3.1 
                    <span className="d-inline ml-5">In addition, any products or services you purchase from us will be governed by separate terms and conditions. These can be found here.</span>
                </p>


                <p>4. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Appstore terms also apply
                    </span>
                </p>
                <p>4.1  
                    <span className="d-inline ml-5">The ways in which you can use the App and Documentation may also be controlled by Apple, Google Play or other Android app store’s rules and policies and these rules and policies will apply instead of these terms where there are differences between the two.</span>
                </p>

                <p>5. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Operating system requirements
                    </span>
                </p>
                <p>5.1  
                    <span className="d-inline ml-5">This app requires a iPhone or iPad  device with a minimum of 100MB of memory and the iOS operating system of version 13.0 and above.</span>
                </p>
                <p>5.2 
                    <span className="d-inline ml-5">This app requires a Android phone or tablet device with a minimum of  200MB of memory and the Android operating system of API version 23 (Android 6.0 Marshmello) and above.</span>
                </p>

                <p>6. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Support for the App and how to tell us about problems
                    </span>
                </p>
                <p>6.1  
                    <span className="d-inline ml-5">Support. If you want to learn more about the App or have any problems it please take a look at our support resources at https://www.shophumm.com/uk/.</span>
                </p>
                <p>6.2 
                    <span className="d-inline ml-5">Contacting us (including with complaints). If you think the App is faulty or misdescribed or wish to contact us for any other reason please email our customer service team at contactuk@shophumm.com or call them on  028 9142 2113.</span>
                </p>
                <p>6.3
                    <span className="d-inline ml-5">How we will communicate with you. If we have to contact you we will do so by email, by SMS or by pre-paid post, using the contact details you have provided to us.</span>
                </p>


                <p>7. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    How you may use the App, including how many devices you may use it on
                    </span>
                </p>
                <p>7.1
                    <span className="d-inline ml-5">In return for your agreeing to comply with these terms you may:</span>
                </p>
                <p>
                <ul> 
                    <li>download or stream a copy of the App onto any IOS or Android device and view, use and display the App on such devices for your personal purposes only;</li>
                    <li>set up an account in order to purchase products or services we offer and access related information;</li>
                    <li>use any Documentation to support your permitted use of the App;</li>
                    <li>provided you comply with these terms, make up to one copy of the App and the Documentation for back-up purposes; and</li>
                    <li>receive and use any free supplementary software code or update of the App incorporating “patches” and corrections of errors as we may provide to you.</li>
                </ul>
                </p>


                <p>8. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    You must be 18 to accept these terms and download the App
                    </span>
                </p>
                <p>8.1
                    <span className="d-inline ml-5">You must be 18 or over to accept these terms and download the App.</span>
                </p>


                <p>9. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    You may not transfer the App to someone else
                    </span>
                </p>
                <p>9.1
                    <span className="d-inline ml-5">We are giving you personally the right to use the App as set out above. You must not transfer the App to someone else, whether for money, for anything else or for free. If you sell any device on which the App is installed, you must remove the App from it.</span>
                </p>


                <p>10. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Changes to these terms
                    </span>
                </p>
                <p>10.1
                    <span className="d-inline ml-5">We may need to change these terms to reflect changes in law or best practice or to deal with additional features which we introduce.</span>
                </p>
                <p>10.2
                    <span className="d-inline ml-5">We will give you at least 30 days’ notice of any change by sending you an electronic notification with details of the change or notifying you of a change when you next start the App.</span>
                </p>
                <p>10.3
                    <span className="d-inline ml-5">If you do not accept the notified changes you will not be permitted to continue to use the App.</span>
                </p>


                <p>11. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Update to the App
                    </span>
                </p>
                <p>11.1
                    <span className="d-inline ml-5">From time to time we may automatically update the App to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively we may ask you to update the App for these reasons.</span>
                </p>
                <p>11.2
                    <span className="d-inline ml-5"> If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App.</span>
                </p>
                <p>11.3
                    <span className="d-inline ml-5">The App will always work with the current or previous version of the operating system (as it may be updated from time to time) and match the description of it provided to you when you bought it.</span>
                </p>


                <p>12. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    If someone else owns the phone or device you are using
                    </span>
                </p>
                <p>12.1
                    <span className="d-inline ml-5">If you download or stream the App onto any phone or other device not owned by you, you must have the owner’s permission to do so. You will be responsible for complying with these terms, whether or not you own the phone or other device.</span>
                </p>


                <p>13. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    We may collect technical data about your device
                    </span>
                </p>
                <p>13.1
                    <span className="d-inline ml-5">By using the App, you agree to us collecting and using technical information about the devices you use the App on and related software, hardware and peripherals to improve our products.</span>
                </p>


                <p>14. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    We may collect location data (but you can turn location services off)
                    </span>
                </p>
                <p>14.1
                    <span className="d-inline ml-5">The App may make use of location data sent from your devices. You can turn off this functionality at any time by turning off the location services settings for the App on the device. If you use these services, you consent to us and our affiliates’ and licensees’ transmission, collection, retention, maintenance, processing and use of your location data and queries to provide and improve location-based and road traffic-based products and services.</span>
                </p>

                <p>15. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    We are not responsible for third party websites you link to
                    </span>
                </p>
                <p>15.1
                    <span className="d-inline ml-5">The App may contain links to other independent websites which are not provided by us. Such independent sites are not under our control, and we are not responsible for and have not checked and approved their content or their privacy policies (if any).</span>
                </p>
                <p>15.2
                    <span className="d-inline ml-5">You will need to make your own independent judgement about whether to use any such independent sites, including whether to buy any products or services offered by them.</span>
                </p>
                <p>15.3
                    <span className="d-inline ml-5">In some limited circumstances, the App may contain links to websites provided by us and your access and use of these websites may be subject to separate website terms of use.</span>
                </p>


                <p>16. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Licence restrictions
                    </span>
                </p>
                <p>16.1
                    <span className="d-inline ml-5">
                    You agree that you will:</span>
                </p>
                <p>
                <ul> 
                    <li>not rent, lease, sub-license, loan, provide, or otherwise make available, the App in any form, in whole or in part to any person without prior written consent from us;</li>
                    <li>not copy the App or Documentation, except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;</li>
                    <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the App or Documentation nor permit the App or any part of it to be combined with, or become incorporated in, any other programs, except as necessary to use the App on devices as permitted in these terms;</li>
                    <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (Permitted Objective), and provided that the information obtained by you during such activities:</li>
                    <li>is not disclosed or communicated without the licensor’s prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</li>
                    <li>is not used to create any software that is substantially similar in its expression to the App;</li>
                    <li>is kept secure; and</li>
                    <li>is used only for the Permitted Objective;</li>
                    <li>comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App.</li>
                </ul>
                </p>
                

                <p>17. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Acceptable use restrictions
                    </span>
                </p>
                <p>17.1
                    <span className="d-inline ml-5">You must:</span>
                </p>
                <p>
                    <ul> 
                        <li>not use the App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the App or any operating system;</li>
                        <li>not infringe our intellectual property rights or those of any third party in relation to your use of the App, including by the submission of any material (to the extent that such use is not licensed by these terms);</li>
                        <li>not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the App;</li>
                        <li>not use the App in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and</li>
                        <li>not collect or harvest any information or data from our systems or attempt to decipher any transmissions to or from the servers running any service.</li>
                    </ul>
                </p>


                <p>18. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Intellectual property rights
                    </span>
                </p>
                <p>18.1
                    <span className="d-inline ml-5">All intellectual property rights in the App and the Documentation throughout the world belong to us (or our licensors) and the rights in the App are licensed (not sold) to you. You have no intellectual property rights in, or to, the App or the Documentation other than the right to use them in accordance with these terms.</span>
                </p>



                <p>19. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Our responsibility for loss or damage suffered by you
                    </span>
                </p>
                <p>19.1
                    <span className="d-inline ml-5">We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.</span>
                </p>
                <p>19.2
                    <span className="d-inline ml-5">We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</span>
                </p>
                <p>19.3
                    <span className="d-inline ml-5">When we are liable for damage to your property. If defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</span>
                </p>
                <p>19.4
                    <span className="d-inline ml-5">We are not liable for business losses. The App is for domestic and private use. If you use the App for any commercial, business or resale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</span>
                </p>
                <p>19.5
                    <span className="d-inline ml-5">Limitations to the App. The App is provided for general information purposes only. They do not offer advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of information obtained from the App. Although we make reasonable efforts to update the information provided by the App, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up to date. Nothing in this clause will however restrict or limit our legal obligations regarding the provision of information for any products or services you purchase from us.</span>
                </p>
                <p>19.6
                    <span className="d-inline ml-5">Check that the App is suitable for you. The App has not been developed to meet your individual requirements. Please check that the facilities and functions of the App (as described on the Appstore site and in the Documentation) meet your requirements.</span>
                </p>
                <p>19.7
                    <span className="d-inline ml-5">We are not responsible for events outside our control. If our provision of support for the App is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract with us.</span>
                </p>



                <p>20. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    We may end your rights to use the App if you break these terms
                    </span>
                </p>
                <p>20.1
                    <span className="d-inline ml-5">We may end your rights to use the App at any time by contacting you if you have broken these terms in a serious way. If what you have done can be put right we will give you a reasonable opportunity to do so.</span>
                </p>
                <p>20.2
                    <span className="d-inline ml-5">If we end your rights to use the App:</span>
                </p>
                <p className="ml-5">20.2.1
                    <span className="d-inline ml-5">You must stop all activities authorised by these terms, including your use of the App.</span>
                </p>
                <p className="ml-5">20.2.2
                    <span className="d-inline ml-5">You must delete or remove the App from all devices in your possession and immediately destroy all copies of the App which you have and confirm to us that you have done this.</span>
                </p>
                <p className="ml-5">20.2.3
                    <span className="d-inline ml-5">We may remotely access your devices and remove the App from them.</span>
                </p>
                <p className="ml-5">20.2.4
                    <span className="d-inline ml-5">We may take such other actions including, without limitation, legal action as we consider appropriate.</span>
                </p>
                <p>20.3
                    <span className="d-inline ml-5">If you breach these terms, or if we receive a request from a law enforcement authority to do so, we may report your identity, details of any breach and any other information we consider necessary to relevant law enforcement authorities.</span>
                </p>



                <p>21. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    We may transfer this agreement to someone else
                    </span>
                </p>
                <p>21.1
                    <span className="d-inline ml-5">We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</span>
                </p>



                <p>22. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    You need our consent to transfer your rights to someone else
                    </span>
                </p>
                <p>22.1
                    <span className="d-inline ml-5">You may only transfer your rights or your obligations under these terms to another person if we agree in writing.</span>
                </p>


                <p>23. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    No rights for third parties
                    </span>
                </p>
                <p>23.1
                    <span className="d-inline ml-5">This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</span>
                </p>


                <p>24. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    If a court finds part of this contract illegal, the rest will continue in force
                    </span>
                </p>
                <p>24.1
                    <span className="d-inline ml-5">Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</span>
                </p>


                <p>25. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Even if we delay in enforcing this contract, we can still enforce it later
                    </span>
                </p>
                <p>25.1
                    <span className="d-inline ml-5">Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</span>
                </p>

                <p>26. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Which laws apply to this contract and where you may bring legal proceedings
                    </span>
                </p>
                <p>26.1
                    <span className="d-inline ml-5">These terms are governed by the law of England and Wales and the courts of England and Wales have non-exclusive jurisdiction to determine any dispute arising out of or in connection with them.  If you are resident in Scotland you may also bring proceedings in Scotland and if you are resident in Northern Ireland you may also bring proceedings in Northern Ireland. </span>
                </p>

                <p>27. 
                    <span className="d-inline ml-5 font-weight-bolder mobile-terms-page--subheading">
                    Our trade marks are registered
                    </span>
                </p>
                <p>27.1
                    <span className="d-inline ml-5">“HUMM” is a UK registered trade mark of FlexiFi Europe Limited. You are not permitted to use it without express approval, unless it is part of material you are using as permitted under these term</span>
                </p>

        </div>
    );
}