

export const handleAddressFiledValidation = (fieldName, fieldValue) => {
  let inputFiledName = fieldName;
  let inputFiledValue = fieldValue;
  return (
    (
      inputFiledName === 'Current_Flat_No__c' ||
      inputFiledName === 'Current_House_Name__c' ||
      inputFiledName === 'HouseNumber__c'
    ) && /^[a-zA-Z0-9 !(),"£$%^*+|?@~#{}[\];:'\-/.\\]+$/.test(inputFiledValue)
  ) || (
    (
      inputFiledName === 'Address_Line1__c' ||
      inputFiledName === 'Address_Line2__c' ||
      inputFiledName === 'Town__c'
    ) && /^[a-zA-Z0-9 ,()"£$%^*+|?@~#{}[\];:'\-.]*$/.test(inputFiledValue)
  );
}

  export const removeSpecialCharacterInAddressFields = (prepopulateValues,key,prepopulateResult,type) => {
    if(type === 'prePopulate'){
    if(key === 'Address_Line1__c' || key === 'Address_Line2__c' || key === 'Town__c'){
      let prePopulateAddress = prepopulateResult.data.data[key];
      let removeSpecialCharecterPrePopulate = prePopulateAddress ? prePopulateAddress.replace(/[^a-zA-Z0-9 ,()"£$%^*+|?@~#{}[\];:'\-.]/g, ' ').replace(/\s+/g, ' '): null;
      prepopulateValues[key] = removeSpecialCharecterPrePopulate;
    }else if(key === 'Current_Flat_No__c' || key === 'Current_House_Name__c' || key === 'HouseNumber__c'){
      let flatPrePopulateAddress = prepopulateResult.data.data[key];
      let flatRemoveSpecialCharecterPrePopulate = flatPrePopulateAddress ? flatPrePopulateAddress.replace(/[^a-zA-Z0-9 !(),"£$%^*+|?@~#{}[\];:'\-/.\\]/g, ' ').replace(/\s+/g, ' '): null;
      prepopulateValues[key] = flatRemoveSpecialCharecterPrePopulate;
    }

  }else if(type === 'autoAddressPrePopulate'){
    prepopulateValues = validateAutoAddressPrePopulate(key,prepopulateResult,prepopulateValues);
  }
    return prepopulateValues;
  }

  function validateAutoAddressPrePopulate(key,prepopulateResult,prepopulateValues){
    if(key === 'flatNumberInfo' || key === 'buildingNameInfo' || key === 'buildingNumberInfo'){
      let addressValue = prepopulateResult;
      let removeSpecialCharecter = addressValue ? addressValue?.toString().replace(/[^a-zA-Z0-9 !(),"£$%^*+|?@~#{}[\];:'\-/.\\]/g, ' ').replace(/\s+/g, ' ') : '';
      prepopulateValues[key] = removeSpecialCharecter;
    }

    if(key === 'addressLineOneInfo' || key === 'addressLineTwoInfo' || key === 'townInfo'){
      let addressFieldValue = prepopulateResult;
      let removeSpecialCharecterInAddress = addressFieldValue ? addressFieldValue.replace(/[^a-zA-Z0-9 ,()"£$%^*+|?@~#{}[\];:'\-.]/g, ' ').replace(/\s+/g, ' ') : null;
      prepopulateValues[key] = removeSpecialCharecterInAddress;
    }

    return prepopulateValues;
  }