import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Loader from '../loader/loader';
import { footerAddress, formatAmount } from '../../utils/const';
import { analyticsClickEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { connect } from 'react-redux';
import { GTMUserIdAndLoginState } from '../../utils/GtmDatalayer';
import Cookies from 'universal-cookie';

class QuoteCalculatorResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
           partnerName:'',
           partnerId:'',
           repaymentPlan:'',
           hasRepaymentPlanLength:'',
           balance:'',
           hasAmount:'',
           hasPlanValue:'',
           hasAPR:'',
           EMISchedule:'',
           EMISchedules:"",
           hasLimit:2,
           EMIIndexSize:'',
           LT_limit:'',
           isBigThings:false,
           isFlinks:false,
           isConfirmAmount:false ,
           isDecline:false,
           isStep:'',
           partnerImage:'',
           hasHideSeeMore:true,
           resetLimit:2,
           loading:false,
           lt_balance:"",
           isErrorModal:false,
           NoRetailerMessage:'',
           defaultModalMessage:'',
          isDefaultModal:false,
          bt_balance:"",
          ErrorMessage:"",
          amount:"",
          displayData: "",
          retailerLink: "",
          EMISchedulesDetails:{
            isUpFrontPayment:'',
            setup_fee:'',
            upfront:'',
            isRegulatedPlan: false, 
            offerName:'',  
            bearing_instalment_amount: '', 
            bearing_installmentPeriod: '',
            interestFree_installmentAmount: '',
            interestFree_installmentPeriod: '',
            hasInterestFreeInstalments: false,
            Total_upfront:'',
            frequency:'',
            instalment_amount:'',
            Monthly_fee:'',
            Total_amount_payable:'',
            Interest_rate:'',
            Apr:'',
            total_interest:'',
            total_fees:'',
            installmentPeriod:''
          },
          frequency:"",
          SelectedPlan:"",
          Selectplans:"",
          showplan:false,
          Selectedfrequency:'',
          res:"",
          tile_Lt_Limit:'',
          tile_Bt_Limit:'',
          isLastPaymentStatus:false

         }
        this.quoteToolAPI=this.quoteToolAPI.bind(this)
        this.handlePlanChange = this.handlePlanChange.bind(this);
        this.scheduleRender =this.scheduleRender.bind(this);
        this.goBack = this.goBack.bind(this);
        this.returnToShopForPublicQuoteLink = this.returnToShopForPublicQuoteLink.bind(this);

        this.handleOnChange =this.handleOnChange.bind(this)
    }




    componentWillUnmount() {
      clearInterval(this.interval);
    }
  

  async handleOnChange(event) {
    let { formattedValue, value } = event;
    if (value <= 30000) {
      console.log(`PostCheck Value: ${value},DisplayValue: ${formattedValue}`);
      if (parseFloat(value) != 0) {
        await this.setState({
          amount: value,
          ErrorMessage: "",
          displayData: formattedValue,
        });
      } else {
        this.setState({displayData:""})
      }
      if (
        this.state.amount <=
        Number(
          this.getLimit()
        )
      ) {
        console.log(
          typeof Number(
            this.getBalance()
          )
        );
        this.setState({
          ErrorMessage: `Requested amount should be more than £${formatAmount(
            this.getLimit()
          )} .`,
        });
      }
    } else {
      return false;
    }
    if (value.length == 0 && value == "") {
      this.setState({ ErrorMessage: "Amount is required." });
    }
  }

  getBalance = () => {
    if(this.state.bt_balance >= 1){
      return this.state.bt_balance
    }
    else {
      return this.state.lt_balance
    }
  }

  getLimit = () => {
    if( this.state.tile_Bt_Limit >= 1){
      return this.state.tile_Bt_Limit
    }
    else {
      return this.state.tile_Lt_Limit
    }
  }


    async componentDidMount(){
       // Push the customer id and loginState if available on this page to the dataLayer
       window.dataLayer = window.dataLayer || {};
       GTMUserIdAndLoginState();

        const result = this.props.location.state;
        console.log(this.props.location.state.partner.store_name, "...route..");
        console.log("Result",result)
        await this.setState({partnerName:result.partner.store_name,partnerId:result.partner.id,balance:result.balanceDetails,hasAmount:result.requestAmount,res:result.result},()=>{
          this.setRetailerPlanTermforApplication(result);
        });
        this.setState({loading:true})
        this.quoteToolAPI()  

        // save retailer ID for Apply now navigation is clicked
        if(result?.partner?.id){
          localStorage.setItem('RetailerIdFromQuoteTool', result?.partner?.id);
        }
      }

      setRetailerPlanTermforApplication = (result)=>{
        console.log('setRetailerPlanTermforApplication',this.state.res)
        if(this.state.res){
          let retailerPlans = this.state.res?.data?.retailer_plan;
          console.log("retailerPlans",retailerPlans);
          if(retailerPlans?.length > 0){
            let retailerTerm = retailerPlans[0]['term'];
            console.log("retailerTerm",retailerTerm);
            const cookie = new Cookies();
            cookie.remove('retailerId');
            localStorage.removeItem('storeID');
            localStorage.setItem('quote-loan-amount',result.requestAmount)
            localStorage.setItem('quote-loan-term',retailerTerm)
          }
        }
      }
      
      
      async quoteToolAPI(){      
          console.log(this.state.res.data.pca)
          if(this.state.res.data.retailer_referral_link != null && 
            this.state.res.data.retailer_referral_link !== '' && this.state.res.data.retailer_referral_link != undefined)
          {
            this.setState({retailerLink: this.state.res.data.retailer_referral_link})
          }
          else {
            this.setState({retailerLink: '/application'})
            this.props.setCustomPath('/application')
          }
                     
          if(this.state.res){
            const res = this.state.res            
              const overAllIndex= res.data.pca.length
              this.setState({
               EMISchedulesDetails:{
               isUpFrontPayment:res.data.pca[0].isUpFrontPayment,
               setup_fee:res.data.pca[0].setUpFee,
               upfront:res.data.pca[0].upfrontInstallment,
               isRegulatedPlan: res.data.pca[0].isRegulated, 
               offerName: res.data.pca[0].planName, 
               bearing_instalment_amount: res.data.pca[0].remainingInstalmentAmount, 
               bearing_installmentPeriod: res.data.pca[0].remainingInstalmentTimes, 
               interestFree_installmentAmount: res.data.pca[0].interestFreeInstalmentAmount, 
               interestFree_installmentPeriod: res.data.pca[0].interestFreeInstalmentTimes, 
               hasInterestFreeInstalments: res.data.pca[0].hasInterestFreeInstalments,
               Total_upfront:res.data.pca[0].totalUpfrontInstallment,
               frequency:res.data.pca[0].frequency,
               instalment_amount:res.data.pca[0].installmentAmount,
               Monthly_fee:res.data.pca[0].monthlyFee,
               Total_amount_payable:res.data.pca[0].totalAmountPayable,
               Interest_rate:res.data.pca[0].interestRate,
               Apr:res.data.pca[0].apr,
               total_interest:res.data.pca[0].totalInterest,
               total_fees:res.data.pca[0].totalFees,
               installmentPeriod:res.data.pca[0].installmentPeriod,
             },
               EMIIndexSize:overAllIndex,
               EMISchedules:res.data.pca,
               frequency:res.data.pca,
              })
            this.setState({
              SelectedPlan:{  
                isUpFrontPayment:res.data.pca[0].isUpFrontPayment,
                setUpFee:res.data.pca[0].setUpFee,
                upfrontInstallment:res.data.pca[0].upfrontInstallment,
                isRegulated: res.data.pca[0].isRegulated, 
                planName: res.data.pca[0].planName, 
                remainingInstalmentAmount: res.data.pca[0].remainingInstalmentAmount, 
                remainingInstalmentTimes: res.data.pca[0].remainingInstalmentTimes, 
                interestFreeInstalmentAmount: res.data.pca[0].interestFreeInstalmentAmount, 
                interestFreeInstalmentTimes: res.data.pca[0].interestFreeInstalmentTimes, 
                hasInterestFreeInstalments: res.data.pca[0].hasInterestFreeInstalments,
                totalUpfrontInstallment:res.data.pca[0].totalUpfrontInstallment,
                frequency:res.data.pca[0].frequency,
                installmentAmount:res.data.pca[0].installmentAmount,
                monthlyFee:res.data.pca[0].monthlyFee,
                totalAmountPayable:res.data.pca[0].totalAmountPayable,
                interestRate:res.data.pca[0].interestRate,
                apr:res.data.pca[0].apr,
                totalInterest:res.data.pca[0].totalInterest,
                totalFees:res.data.pca[0].totalFees,
                installmentPeriod:res.data.pca[0].installmentPeriod,                
              }
            })
           this.setState({loading:false})
          }
          else {
            return false
          }   
  }

     async handlePlanChange(e){
        await this.setState({hasPlanValue:e.target.value});
        console.log("default value",this.state.hasPlanValue)
            this.selectEMIPlans();
            this.setState({hasLimit:2,hasHideSeeMore:true})
        }
    

    selectEMIPlans= (src)=>{
      console.log('emi',this.state.EMISchedules);

       let schedulePaymentDetails = this.state.EMISchedules.filter(e =>{
         
         return  e.plan_id.includes(src.plan_id)
       }
        );
        this.setQuoteLoanTermForApplication(src);
      this.setState({
        SelectedPlan:schedulePaymentDetails[0],Selectedfrequency:src.planName 
      })
    }

    setQuoteLoanTermForApplication = (src)=>{
      console.log('schedulePaymentDetails',src);
        console.log('src.plan_id',src.plan_id);
        let reatilerAllPlans = this.state.res?.data?.retailer_plan;
        let selctedRetailerTerms = reatilerAllPlans.filter(e =>{
         
          return  e.retailerplan_id.includes(src.plan_id)
        }
         );
         if(selctedRetailerTerms){
          let retailerTerm = selctedRetailerTerms[0]['term'];
              console.log("selectEMIPlans",retailerTerm);
              localStorage.setItem('quote-loan-term',retailerTerm)
         }
         console.log('selctedRetailerTerms',selctedRetailerTerms);
        console.log('selectEMIPlans_retailer_plan',this.state.res);
    }

handleIncreaseLimit = () =>{ 
  return false

}


scheduleRender(){

    return (
        <>


        <ul className="pay-client-listitem">
            {console.log(this.state.EMISchedule, "...Emi_schedule")}            
                                            
        </ul>
        {this.state.EMISchedule && this.state.hasHideSeeMore && this.state.EMIIndexSize>3 &&
        <div style={{textAlign:'center',maxWidth:'322px'}}>
        <button className="seemore-btn" 
         onClick={(_e)=>{this.handleSeeMore(this.state.EMIIndexSize)}}
        >See More</button>
        </div>  }


        {this.state.EMISchedule && !this.state.hasHideSeeMore &&
        <div style={{textAlign:'center',maxWidth:'322px'}}>
        <button className="seemore-btn" 
         onClick={this.handleSeeLess}
        >See Less </button>
        </div>  }
        </>
        )
    
}

handleSeeLess =()=>{
    this.setState({hasLimit:2,hasHideSeeMore:true});

}

handleSeeMore = (value)=>{
    console.log('see more',value)
    this.setState({hasLimit:value,hasHideSeeMore:false})
}
goBack(){
  analyticsClickEvent('quote_back','');
  const { history } = this.props;
  localStorage.setItem("prevRoute", '/quote-calculator-results');
  this.props.setStoreName(this.state.partnerName);
  history.replace(history.goBack({pathname: '/quote-calculator' }));

}

  getClassNames(stateValue) {
    if(stateValue){
        return "main mt-2"
    }
    else {
      return "main"
    }
  }

  getEachStateValues = (checkStateValue) => {
        return formatAmount(checkStateValue)
  }

  formatAprValues = (checkAprValue) => {
    if(checkAprValue > 0){
      return formatAmount(checkAprValue);
    }
    else {
      return checkAprValue + ".00";
    }

  }

  formatUpfrontValues = (checkUpfrontValue) => {
    if(typeof checkUpfrontValue === "number"){
      return formatAmount(checkUpfrontValue.toString())
    }
    else {
      return formatAmount(checkUpfrontValue)
    }
  }

  returnToShopForPublicQuoteLink(){ 
    this.props.setCustomPath('/shop');
    localStorage.removeItem('webView') 
  }

  returnToShopForLoggedUser(){
    localStorage.removeItem('webView');
    analyticsClickEvent('return_to_shop','')
  }

  eventPreventDefault = (event)=> {
    event.preventDefault();
  }

  analyticsclickEventForCalculateAgain = () => {
    analyticsClickEvent('calculate_again','')
  }

  removeLocalStorageWebView = () => {
    localStorage.removeItem('webView');
  }

  getReturnToShopLink(){
    if (localStorage.getItem('sessionId') === null) {
      if(localStorage.getItem('mobile')) {
        return <Link class="return-to-shop-text" to="/login" onClick={this.eventPreventDefault}>
          <span class="limit-text-para">Return to shop</span>
        </Link>
      }
      else if (localStorage.getItem('fromWhere') === 'apply_now') {
        return ('')
      }
      else {
        return <Link className="return-to-shop-text" to={{
          pathname: "/shop", search: '?URLPath='+'shop' }} onClick={this.returnToShopForPublicQuoteLink}>
          <span class="limit-text-para">Return to shop</span>
        </Link>
      }
    }
    else {
      return <Link class="return-to-shop-text" to="/shop"
                onClick={ this.returnToShopForLoggedUser}>
                <span class="limit-text-para">Return to shop</span>
          </Link>
    }
  }

  getCalculateAgainLink() {
    if(localStorage.getItem('sessionId') === null){
      if(localStorage.getItem('mobile')) {
        return (<Link className="quote-apply-now" to="/login" onClick={this.eventPreventDefault}>
          Apply Now
        </Link>);
      }
      else {
        return (
          this.state.retailerLink.includes('https') ?
          <a className="quote-apply-now"
          href={this.state.retailerLink}
          target={this.hyperlinkRedirection()}
          rel="noopener noreferrer" onClick={this.removeLocalStorageWebView}>Apply Now</a>
          :
          <Link className="quote-apply-now"
          to={{
            pathname: this.state.retailerLink,
            search: '?URLPath='+'application',
            state: {redirectPathname: this.props.setCustomPath('/application')}}}
          rel="noopener noreferrer" onClick={this.removeLocalStorageWebView}>Apply Now</Link>
        );
      }
    }
  }

  hyperlinkRedirection = () => {
    if(localStorage.getItem('fromWhere') === 'apply_now'){
      return '_self'
    }
    else if(localStorage.getItem('webView')) {
      return '_blank';
    }
    else {
      return '_self'
    }
  }

  stylingOfHyperlinks = () => {
    if(localStorage.getItem('fromWhere') == 'apply_now'){
      return ('d-flex justify-content-center');
    }
    else {
      return ('d-flex justify-content-around');
    }
  }
   

    render() { 
        return ( 
            <div className="wrapper"> 
            <Loader loaderStatus={this.state.loading}/>
         
            {/* search nav */}
            <section className={ this.getClassNames(localStorage.getItem('webView')) }>
                { localStorage.getItem('webView') ? ' ' :
                <div className="search-fav-section">
                    <div className="container">
                        <div className="d-flex align-items-center top-section-partner">
                            <div className="left-iteQerd">
                                <div className="back-btn-item">
                                    <a className="back-btnQr" onClick={this.goBack}><img src="images/back.svg" alt="<-"/> Back </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                }
            </section> 
            <section className="section py-3">
                <div className="container--quote-tool">
                    <div className="tab-content" id="myTabContent">
                     
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">                        
                            <div className="Qc-section">
                                {localStorage.getItem('webView') ? ' ' : <h3 className="Qc-heading-text">Quote Calculator</h3>}
                                <p className='mb-1'>It is important to borrow only what you can afford to repay, as borrowing more than you can afford may lead to financial difficulties and impact your ability to obtain credit in the future. Late payment fees may also apply, so it is important to make payments on time. Please refer to our <a href="https://www.shophumm.com/uk/product-profile/" rel="noopener noreferrer" target='_blank' className='apply-important-info-link'>T&C's</a> for more information. </p>
                            </div>
                            <div className="qc-price-schedule">
                                <div className="store-price-item">
                                    <div className="price-div-section"> 
                                        <p>Retailer</p>
                                        <h3>{this.state.partnerName}</h3>
                                    </div>
                                    <div className="price-div-section">
                                        <p>Quote Amount</p>
                                        <h3>£{formatAmount(this.state.hasAmount)}</h3>
                                    </div>
                                    <div className="price-div-section payment-method-section" style={{padding:0,margin:0}}>
                                        <p>Offer Name</p>                                         
                                        
                                          {console.log("size",this.state.EMIIndexSize)}
                                          {this.state.EMIIndexSize>1 && this.state.frequency!=""&&
                                          
                                          <div className="drop-down">
                                          <div className="selected-item" onClick={()=>{ this.setState(prevState => ({
                                          showplan: !prevState.showplan
                                          }));}}>
                                           <button className="select--btn-item">
                                             <span>{this.state.Selectedfrequency?this.state.Selectedfrequency:this.state.frequency[0].planName}</span>
                                           </button>
                                           <span><img src={`${this.state.showplan?"images/up-arrow.png":"images/down-arrow.png"}`} alt="down-arrow"/></span>
                                          <div className={`options ${this.state.showplan?"show":""}`}>
                                          <ul> 
                                             
                                            {this.state.frequency.map((src)=>(
                                              
                                              <li key={src.plan_id} 
                                              onClick={()=>{this.selectEMIPlans(src)}} 
                                              >{src.planName}</li>
                                            )
                                            )}
                                          </ul>
                                          </div>
                                          </div>
                                          </div>
                                     
                                          }

                                        {this.state.EMIIndexSize==1 &&
                                          <h3>{this.state.frequency[0].planName}</h3>
                                        }

                                    </div>

                                    <div className="price-div-section">
                                            {/* <div className="form-group">
                                                <label>Term</label>
                                                <input type="text" className="form-control"  name="keyword"
                                                 value={this.state.searchValue} 
                                                 placeholder="12 Months" 
                                                 autoComplete="off"
                                                 onChange={(e)=>this.onHandleChange(e)}/>
                                            </div> */}

                                   

                                             <div className="search-result-item">
                                                <div className={`search-body ${this.state.hasDisplay?'show-option':'hide-option'}`} >
                                                    <ul className="m-0 p-0">
                                                        {this.state.searchValue &&
                                                         this.state.searchResult.map(list =>
                                                        <li className="search-list" key={list.id} 
                                                        onClick={(_e)=>this.suggestionSelected(list)}>{list.store_name}</li>
                                                         )
                                                         
                                                        }
                                                       
                                                        
                                                    </ul>
                                                </div>
                                                        <p className="error-message card-holder-error">{this.state.storeError}</p>
                                            </div>
                                            </div>
                                             
                                    {/* <div className="price-div-section">
                                        <p>APR</p> */}
                                        {/* <h3>{this.state.hasAPR}%</h3> */}
                                    {/* </div> */}
                                    {/* <div className="price-div-section">                                    
                                        <div className="form-group">
                                            <label>Plan</label>
                                            {this.state.hasRepaymentPlanLength>1 && this.state.hasRepaymentPlanLength!=""&&
                                            <select class="form-control"  name="selectedPlan" value={this.state.hasPlanValue} 
                                             onChange={(e)=>{this.handlePlanChange(e)}}>      
                                                 {/* <option value=''>Select</option> */}                                                

                                            {/* </select>
                                          }
                                            {this.state.hasRepaymentPlanLength==1 && 
                                             <h3>{this.state.repaymentPlan.[0].frequency_plan}</h3>
                                              } */}

                                          
                                        {/* </div>
                                    </div> */}
                                </div>

                                    {/* Single plan logic */}
                                
                                {this.state.EMISchedules.length==1 && 
                                <div className="store-payment-details">
                                  { (this.state.EMISchedulesDetails.isUpFrontPayment|| !this.state.EMISchedulesDetails.isUpFrontPayment) &&
                                  <div className="payments-col">
                                    <div className="row">
                                      <label className='header-title'>Upfront Payment</label>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Setup Fee</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.setup_fee)}</div>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Upfront Instalment</div>
                                      <div className="payments-section-value">£{this.formatUpfrontValues(this.state.EMISchedulesDetails.upfront)}</div>
                                    </div>
                                    <div className="row row-total row-bold">
                                      <div className="payments-section-title">Total Upfront Payment</div>
                                      <div className="payments-section-value">£{this.formatUpfrontValues(this.state.EMISchedulesDetails.Total_upfront)}</div>
                                    </div>
                                  </div>
                                  }

                                  <div className="payments-col">
                                    <div className="row">
                                      <label className='header-title'>Instalment Payments</label>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Frequency</div>
                                      <div className="payments-section-value">{this.state.EMISchedulesDetails.frequency}</div>
                                    </div>
                                    
                                    {/* Displaying Interest Free Instalment Amount and interest Bearing Amount */}
                                    <div className="row">
                                      <div className="payments-section-title">Interest Free Instalments</div>
                                      <div className="payments-section-value">
                                      £{this.state.EMISchedulesDetails.interestFree_installmentAmount!=="0"&&
                                        this.formatUpfrontValues(this.state.EMISchedulesDetails.interestFree_installmentAmount) + " x " + this.state.EMISchedulesDetails.interestFree_installmentPeriod
                                      }                                      
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Interest Bearing Instalments</div>
                                      <div className="payments-section-value">
                                      £{this.state.EMISchedulesDetails.bearing_instalment_amount!=="0"&&
                                        this.formatUpfrontValues(this.state.EMISchedulesDetails.bearing_instalment_amount) + " x " + this.state.EMISchedulesDetails.bearing_installmentPeriod
                                      }
                                      </div>
                                    </div>
                                    {/* Displaying monthly Account Fee */}
                                    <div className="row">
                                      <div className="payments-section-title">Account Fee</div>
                                      <div className="payments-section-value">
                                      £{this.state.EMISchedulesDetails.Monthly_fee!=="0"&&
                                        formatAmount(this.state.EMISchedulesDetails.Monthly_fee) + " x " + this.state.EMISchedulesDetails.installmentPeriod
                                      }
                                      {this.state.EMISchedulesDetails.Monthly_fee === "0" && formatAmount(this.state.EMISchedulesDetails.Monthly_fee)}
                                       </div>
                                    </div>
                                    <div className="row row-total row-bold">
                                      <div className="payments-section-title">Total Amount Payable*</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.Total_amount_payable)}</div>
                                    </div>
                                  </div>
                                  

                                  <div className="payments-col">
                                    <div className="row">
                                      <label className='header-title'>Instalment Payments</label>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">Interest Rate</div>
                                      <div className="payments-section-value">{this.formatAprValues(this.state.EMISchedulesDetails.Interest_rate)}%</div>
                                    </div>
                                    <div className="row">
                                      <div className="payments-section-title">APR</div>
                                      <div className="payments-section-value">{this.formatAprValues(this.state.EMISchedulesDetails.Apr)}%</div>
                                    </div>
                                    <div className="row row-total row-bold">
                                      <div className="payments-section-title">* includes total interest</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.total_interest)}</div>
                                    </div>
                                    <div className="row row-bold">
                                      <div className="payments-section-title">* includes total fees</div>
                                      <div className="payments-section-value">£{formatAmount(this.state.EMISchedulesDetails.total_fees)}</div>
                                    </div>
                                  </div>
                                </div>  
                                }

                                {/* multiple plan logic */}                                
                                {this.state.EMISchedules.length>1 && this.state.EMISchedules!="" && 
                                <div className="store-payment-details">
                                  
                                {console.log("isUpfront",this.state.EMISchedulesDetails.isUpFrontPayment)}
                                {console.log("isUpfront",this.state.SelectedPlan.isUpFrontPayment)}
                                {console.log("final",this.state.SelectedPlan.isUpFrontPayment==undefined ?
                                 this.state.EMISchedulesDetails.isUpFrontPayment:
                                 this.state.SelectedPlan.isUpFrontPayment)}
                               
                               {console.log(this.state.SelectedPlan)}
                                { (this.state.SelectedPlan.isUpFrontPayment || !this.state.SelectedPlan.isUpFrontPayment) && 
                                <div className="payments-col">
                                  <div className="row">
                                    <label className='header-title'>Upfront Payment</label>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Setup Fee</div>
                                    <div className="payments-section-value">£{
                                    this.getEachStateValues(this.state.SelectedPlan.setUpFee)
                                    }</div>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Upfront Instalment</div>
                                   
                                    <div className="payments-section-value">£{                                    
                                    this.formatUpfrontValues(this.state.SelectedPlan.upfrontInstallment)                                    
                                    }</div>
                                  </div>
                                  <div className="row row-total row-bold">
                                    <div className="payments-section-title">Total Upfront Payment</div>
                                    <div className="payments-section-value">£{
                                    this.formatUpfrontValues(this.state.SelectedPlan.totalUpfrontInstallment)
                                    }</div>
                                  </div>
                                </div>
                                }
                                

                                <div className="payments-col">
                                  <div className="row">
                                    <label className='header-title'>Instalment Payments</label>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Frequency</div>
                                    <div className="payments-section-value">{this.state.SelectedPlan.frequency}</div>
                                  </div>
                                  
                                  {/* Displaying Interest Free Instalment Amount and interest Bearing Amount */}
                                  <div className="row">
                                    <div className="payments-section-title">Interest Free Instalments</div>
                                    <div className="payments-section-value">£{                                    
                                    this.state.SelectedPlan.interestFreeInstalmentAmount!=="0" && 
                                    this.formatUpfrontValues(this.state.SelectedPlan.interestFreeInstalmentAmount) +" x "+this.state.SelectedPlan.interestFreeInstalmentTimes                                    
                                    }                                    
     

                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Interest Bearing Instalments</div>
                                    <div className="payments-section-value">£{                                    
                                    this.state.SelectedPlan.remainingInstalmentAmount!=="0" && 
                                    this.formatUpfrontValues(this.state.SelectedPlan.remainingInstalmentAmount) +" x "+this.state.SelectedPlan.remainingInstalmentTimes                                    
                                    }
     

                                    </div>
                                  </div>
                                  {/* Displaying monthly Account Fee */}                                  
                                  
                                  <div className="row">
                                    <div className="payments-section-title">Account Fee</div>
                                    <div className="payments-section-value">£
                                    {
                                    this.state.SelectedPlan.monthlyFee!=="0" && 
                                    formatAmount(this.state.SelectedPlan.monthlyFee) +" x "+this.state.SelectedPlan.installmentPeriod                                    
                                    }

                                    {
                                    this.state.SelectedPlan.monthlyFee==="0" && 
                                    formatAmount(this.state.SelectedPlan.monthlyFee)                                    
                                    }

                                    </div>
                                  </div>
                                  <div className="row row-total row-bold">
                                    <div className="payments-section-title">Total Amount Payable*</div>
                                    <div className="payments-section-value">£{
                                    this.getEachStateValues(this.state.SelectedPlan.totalAmountPayable)                                    
                                    }</div>
                                  </div>
                                </div>
                                

                                <div className="payments-col">
                                  <div className="row">
                                    <label className='header-title'>Instalment Payments</label>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">Interest Rate</div>
                                    <div className="payments-section-value">{this.formatAprValues(this.state.SelectedPlan.interestRate)}%</div>
                                  </div>
                                  <div className="row">
                                    <div className="payments-section-title">APR</div>                                    
                                    <div className="payments-section-value">{this.formatAprValues(this.state.SelectedPlan.apr)}%</div>
                                  </div>
                                  <div className="row row-total row-bold">
                                    <div className="payments-section-title">* includes total interest</div>
                                    <div className="payments-section-value">£{formatAmount(this.state.SelectedPlan.totalInterest)}</div>
                                  </div>
                                  <div className="row row-bold">
                                    <div className="payments-section-title">* includes total fees</div>
                                    <div className="payments-section-value">£{
                                    this.getEachStateValues(this.state.SelectedPlan.totalFees)                                    
                                    }</div>
                                  </div>
                                </div>
                              </div>  
                                }
                            
                                

                                <div className="store-payment-limit">                                
                                  { 
                                  !this.handleIncreaseLimit() && 
                                      <>
                                        <div className="limit-section">
                                        {localStorage.getItem('sessionId') !== null &&
                                        <a className="quote-apply-now"
                                        href={this.state.retailerLink}
                                        target={localStorage.removeItem('webView') ? '_blank' : '_self'}
                                        rel="noopener noreferrer"
                                        onClick={ () => {
                                          localStorage.removeItem('webView')
                                          analyticsClickEvent('quote-tool-apply-now','')}}
                                        >Apply Now</a> 
                                        }
                                        {this.getCalculateAgainLink()}                                                                    
                                        </div>
                                        <div className={this.stylingOfHyperlinks()}>
                                        <div className='d-inline'>
                                          <Link className="calculate-again-text" 
                                          to={{
                                            pathname: '/quote-calculator',
                                            search: '?store='+this.state.partnerId
                                          }}
                                          onClick={this.analyticsclickEventForCalculateAgain}
                                           > 
                                          <span className='limit-text-para'>Calculate again</span>
                                          </Link>
                                        </div>
                                        <div className='d-inline'>
                                        {this.getReturnToShopLink()}
                                        </div>
                                        </div>    
                                      </>                              
                                 }                                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Quote-footer mx-5 mt-4 mb-2'>
                    <p className='mx-auto mb-0'>
                      <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                      humm</span> is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478. <span style={{fontWeight: "600",display: "inline",color: "#212529"}}>
                      humm</span> offers both regulated and unregulated products. 
                    Our interest free plans which are repayable within 12 months and in no more than 12 instalments are not regulated by the Financial Conduct Authority. Registered office: {footerAddress}
                    Company Number: NI675430. Data Protection Register Number: ZB029507
                    </p>
                </div>
            </section>
            {/* <ul class="nav nav-tabs" id="myTab" role="tablist" style={{maxWidth:"280px",margin:'0 auto'}}>
                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Prev</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Next</a>
                </li>
            </ul> */}
                    

         
        
    </div> );
    }
}
 
export const mapStateToProps = (state) => {
  return {
      customPathname: state.dataStore.customPathname,
      resultStoreName: state.dataStore.resultStoreName
  };
 };

export const mapDispatchToProps = (dispatch) => {
  return {
      setCustomPath:(data)=>{
       return dispatch({
           type: 'SET_CUSTOM_PATH',
           value: data
       });
      },
      setStoreName:(data)=>{
        return dispatch({
            type: 'SET_STORE_NAME',
            value: data
        });
       },
  }
 }

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(QuoteCalculatorResults));

