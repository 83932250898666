import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const applicationSave = async (formData) => {
    let url = config.hummBackendAppURL+'application/submit_application'

   try {
        const applicationSubmittedResponse = await axios.post(url, formData, { headers: sessionID('secured') });
        return applicationSubmittedResponse;
    } catch (error) {
        console.log('applicationSave_error',error);
        userLogs(url, formData, error);
        if (error.response) {
            handlingSession(error.response.status);
        }
        return error.response;
    }


};

export const openBankingInitialize = async (formData) => {
    let url = config.hummBackendAppURL+'open-banking/initialize'

   try {
        const applicationSubmittedResponse = await axios.post(url, formData, { headers: sessionID('secured') });
        return applicationSubmittedResponse;
    } catch (error) {
        userLogs(url, formData, error);
        if (error.response) {
            handlingSession(error.response.status);
        }
        return error.response;
    }


};

export const continueOpenBanking = async (formData) => {
    let url = config.hummBackendAppURL+'open-banking/continue-open-banking'

   try {
        const applicationSubmittedResponse = await axios.post(url, formData, { headers: sessionID('secured') });
        return applicationSubmittedResponse;
    } catch (error) {
        userLogs(url, formData, error);
        if (error.response) {
            handlingSession(error.response.status);
        }
        return error.response;
    }


};

export const getOBApplicationStatus = async (formData) => {
    let url = config.hummBackendAppURL+'application/get-ob-decision'

   try {
        const applicationSubmittedResponse = await axios.post(url, formData, { headers: sessionID('secured') });
        return applicationSubmittedResponse;
    } catch (error) {
        userLogs(url, formData, error);
        if (error.response) {
            handlingSession(error.response.status);
        }
        return error.response;
    }


};